<template>
    <div class="verifyEmailDialog" v-if="show">
        <div class="bg"></div>
        <div class="contentWarp">
            <div class="tit">
                A verification link has been sent to your email address.
            </div>
            <div class="dec1 mt16">
                Please click on the link that has just been sent to your email address to activate your account.
            </div>
            <tt-m-button
                :class="['btn', timeCount ? 'disabled' : '']"
                :disable="timeCount"
                :loading="loading"
                @click="sendLink"
            >
                <template v-if="timeCount">Try Again in {{timeCount}}s</template>
                <template v-else>Resend the email</template>
            </tt-m-button>
            <div class="errorMsg" v-if="errorMsg">{{ errorMsg }}</div>
        </div>
    </div>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig();
const show = ref(false)
const loading = ref(false)
const verifyLoading = ref(false)
const timer = ref(false)
const timeCount = ref(0)
const route = useRoute();
const hasVerifyDialog = ref(false)
const errorMsg = ref('')
const verifyEmailDialogAction = useState("verifyEmailDialogAction")
const refreshMyUserInfoLoading = useState("refreshMyUserInfoLoading");
watch(
    () => verifyEmailDialogAction.value,
    (n) => {
        init()
    }
);
onMounted(()=>{
    clearInterval(timer.value)
    timeCount.value = 0
    errorMsg.value = ''
    verifyLoading.value = false
    hasVerifyDialog.value = true
    if (hasVerifyDialog.value) {
        if (route && route?.query && route.query?.vk) {
            autoVerifyEmail(route.query?.vk)
        }
        if (refreshMyUserInfoLoading.value == true || refreshMyUserInfoLoading.value == undefined) {
            let waitUserInfoRefresh = window.setInterval(()=>{
                if (refreshMyUserInfoLoading.value == false) {
                    clearInterval(waitUserInfoRefresh)
                    init()
                }
            }, 1000)
        } else {
            init()
        }
    }
})
const init = () => {
    const userinfo = useCookie("userinfo");
    if (!(userinfo.value?.visitor_token || userinfo.value == undefined) && parseInt(userinfo.value.email_verified) != 1 && parseInt(userinfo.value.is_edu) == 1) {
        show.value = true
    } else {
        show.value = false
    }
    if (show.value) {
        if (typeof localStorage != 'undefined' && localStorage.getItem('lastSendVerifyEmailLinkTime') != null) {
            let count = parseInt((new Date().getTime() - parseInt(localStorage.getItem('lastSendVerifyEmailLinkTime'))) / 1000)
            if (60 - count > 0) {
                setTimer(60 - count)
            } else {
                verifyLoading.value ? '' : sendLink()
            }
        } else {
            verifyLoading.value ? '' : sendLink()
        }
    }
}
const hideDialog = () => {
    show.value = false
}
const sendLink = () => {
    errorMsg.value = ''
    if (loading.value || timeCount.value > 0) return
    typeof localStorage != 'undefined' ? localStorage.setItem('lastSendVerifyEmailLinkTime', new Date().getTime()) : ''
    setTimer(60)
    loading.value = true
    sendVerifyEmailLink().then((res) => {
        console.log(res)
    }).finally(()=>{
        loading.value = false
    })
}
const setTimer = (count) => {
    timeCount.value = count
    timer.value = window.setInterval(()=>{
        timeCount.value--
        if (timeCount.value <= 0) {
            clearInterval(timer.value)
        } 
    }, 1000)
}
const autoVerifyEmail = (key) => {
    if (verifyLoading.value) return
    verifyLoading.value = true
    verifyEmail({
        key: key
    }).then(async (res) => {
        errorMsg.value = ''
        const userinfo = useCookie("userinfo")
        const isMobile = useState("isMobile")
        typeof sessionStorage != 'undefined' ? sessionStorage.setItem('newRegister', 1) : ''
        if (!(userinfo.value?.visitor_token || userinfo.value == undefined)) {
            userinfo.value.email_verified = 1
            await isMobile.value ? navigateTo('/foryou') : ''
            const registerSuccessDialogAction = useState("registerSuccessDialogAction")
            registerSuccessDialogAction.value = new Date().getTime()
        } else {
            isMobile.value ? navigateTo('/login') : ''
        }
        show.value = false
        showToast({
            duration: 5000,
            message: 'Your email address has been verified successfully.',
            position: "top",
        });
    }).catch((err)=>{
        showToast({
            duration: 5000,
            message: err?.data && err.data?.msg ? err.data.msg : 'Oops! There was a problem connecting to the network. Please refresh!',
            position: "top",
        });
        errorMsg.value = err?.data && err.data?.msg ? err.data.msg : 'Oops! There was a problem connecting to the network. Please refresh!'
    })
    .finally(async ()=>{
        verifyLoading.value = false
    })
}
onUnmounted(() => {
    show.value = false
})
</script>
<style scoped lang="scss">
.verifyEmailDialog {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .bg {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
    }
    .contentWarp {
        box-sizing: border-box;
        background-color: #2e2e2e;
        border-radius: 0.8rem;
        margin: 0 auto;
        width: calc(100% - 4rem);
        max-width: 40rem;
        padding: 3.2rem 2rem;
        position: relative;
        z-index: 3;
    }
    .contentWarp .tit {
        @extend .publicFont3;
        font-size: 1.6rem;
        color: $mainftColor;
        line-height: 2.3rem;
        text-align: center;
    }
    .mt16 {
        margin-top: 1.6rem;
    }
    .dec1 {
        font-size: 1.5rem;
        color: $mainftColor;
        line-height: 2.6rem;
        text-align: center;
    }
    .btn {
        width: 25rem;
        height: 4rem;
        line-height: 4rem;
        text-align: center;
        background-color: $mainftColor;
        color: $mainColor;
        @extend .publicFont3;
        font-size: 1.6rem;
        margin: 2.4rem auto 0;
        border-radius: 2rem;
        &.disabled {
            opacity: .8;
        }
    }
    .errorMsg {
        color: #f72f2f;
        text-align: center;
        margin: 1.6rem auto 0;
    }
}
</style>