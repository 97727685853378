
export default defineNuxtPlugin(async () => {
    const { engine } = useRuntimeConfig().public;
    const language = await import(`../i18n/${engine}/index.js`);
    return {
        provide: {
            language: language.default,
        },
    };
});
