import type { RouterConfig } from "@nuxt/schema";
// 自定义路由
export default <RouterConfig>{
    routes: (_routes: any) => {
        const pages = [
            //介绍页
            // {
            //     path: "",
            //     name: "a",
            //     redirect: "/introduce",
            //     // component: () => import("~/pages/introduce.vue"),
            // },
            // {
            //     path: "/introduce",
            //     name: "introduce",
            //     component: () => import("~/pages/introduce.vue"),
            // },
            // // 首页
            // {
            //     path: "/foryou",
            //     name: "foryou",
            //     component: () => import("~/pages/index.vue"),
            //     props: { from: "foryou" },
            // },
            {
                path: "/follow",
                name: "follow",
                component: () => import("~/pages/foryou.vue"),
                props: { from: "follow" },
            },
            // 登录注册
            {
                path: "/signup",
                name: "signup",
                component: () => import("~/pages/auth/index.vue"),
            },
            {
                path: "/login",
                name: "login",
                component: () => import("~/pages/auth/index.vue"),
            },
            // 如果在注册时，校验到邮箱|手机已注册，就跳转到这个页面
            {
                path: "/login/with-signup/:type",
                name: "login-with-signup",
                component: () => import("~/pages/auth/phone-or-email.vue"),
            },
            // 手机号码 | 邮箱 - 区分登录注册
            // from - signup | login
            // act - phone | email
            {
                path: "/:from/phone-or-email",
                name: "phone-or-email",
                component: () => import("~/pages/auth/phone-or-email.vue"),
            },
            {
                path: "/:from/phone-or-email/:act",
                name: "phone-or-email-act",
                component: () => import("~/pages/auth/phone-or-email.vue"),
            },
            // 设置
            {
                path: "/setting",
                name: "setting",
                component: () => import("~/pages/setting/index.vue"),
            },
            // 其他人的个人主页
            {
                path: "/@:profId",
                name: "user-page",
                component: () => import("~/pages/user-page.vue"),
            },
            // 关注 | 粉丝列表，本人与他人公用
            {
                path: "/follow-list/@:userId/:type/:username",
                name: "follow-list",
                component: () => import("~/pages/follow-list"),
            },
            // {
            // 	path:'/post',
            // 	name:'post',
            // 	component: () => import("~/pages/post.vue")
            // }
        ];
        pages.forEach((page: any) => {
            if (_routes.findIndex((item: any) => item.path == page.path) == -1) {
                _routes.push(page);
            }
        });
        return _routes;
    },
};
