// 定义关于user的store
import { defineStore } from "pinia";

const useUser = defineStore('user', {
    state:() => ({
        myUserInfo: {},
        mypageInfo: {},
        selfMyPost:[],
        isFirstTimeEnter:true,
        token: '',
        homeList: [],
        homeFollowList:[],
        userData:[],
        currItem:'',
        isBottom:false,//判断个人主页是否触底
        isUserBottom:false,//判断他人主页是否触底
        isCloseAvg:false,//判断是否出现火花动效
        ratesShow:false,
    }),
    getters: {},
    actions: {}
})

export default useUser