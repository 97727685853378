<template>
    <div class="customDialog" v-if="customDialogData.show">
        <div class="bg" @touchstart="closeSiteTip"></div>
        <div class="dialogWarp">
            <p>{{ customDialogData.message }}</p>
            <div class="btn" @click="customDialogData?.clickCallback ? customDialogData.clickCallback() : ''; closeSiteTip()">{{ customDialogData.btnText || 'OK' }}</div>
        </div>
    </div>
</template>

<script setup>
const customDialogData = useState('customDialogData', ()=>{
    return {
        show: false,
        title: '', //标题
        message: '', //内容
        btnText: 'OK', //默认按钮文字,
        clickCallback: '' //点击按钮回调函数
    }
});
const closeSiteTip = () => {
    var event = window.event || arguments[0] || ''
    if (event) {
        event.stopPropagation()
    }
    customDialogData.value = {
        show: false,
        title: '', //标题
        message: '', //内容
        btnText: 'OK', //默认按钮文字
        clickCallback: '' //点击按钮回调函数
    }
}
</script>
<style scoped lang="scss">
.customDialog {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 999999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .dialogWarp {
        background-color: #2e2e2e;
        width: calc(100% - 4rem);
        max-width: 35rem;
        margin: 0 auto;
        border-radius: 0.8rem;
        box-sizing: border-box;
        padding: 2.4rem 1.6rem;
        position: relative;
        z-index: 2;
    }
    p {
        font-family: Poppins-Regular;
        font-size: 1.6rem;
        line-height: 2.6rem;
        text-align: center;
        margin: 0;
    }
    .btn {
        width: 25rem;
        height: 4rem;
        margin: 2.4rem auto 0;
        line-height: 4rem;
        border-radius: 2rem;
        text-align: center;
        color: $mainColor;
        font-family: Poppins-Medium;
        font-size: 1.6rem;
        letter-spacing: 0.03rem;
        background-color: $mainftColor;
    }
}
</style>