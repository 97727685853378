<template>
    <div class="customTips" v-if="customTips.show">
        <div class="bg" @touchstart="closeSiteTip"></div>
        <div class="dialogWarp">
            <p>{{ customTips.message }}</p>
            <div class="statusBox">
                <div class="statusIco">
                    <IconSuccessTips />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const customTips = useState('customTips', ()=>{
    return {
        show: false,
        title: '', //标题
        message: '', //内容
    }
});
const closeSiteTip = () => {
    customTips.value = {
        show: false,
        title: '', //标题
        message: '', //内容
    }
}
setTimeout(()=>{
    if(customTips.value.show){
        closeSiteTip()
    }
}, 3000)
</script>
<style scoped lang="scss">
.customTips {
    z-index: 9999;
    position: absolute;
    top: 1.5rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: calc(100% - 3.2rem);
    height: 8rem;
    box-sizing: border-box;
    animation: showA 0.2s linear;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    background-color: #fff;
    overflow: hidden;
    &.defaultCase .contentWrap {
        text-transform: initial;
    }
    p{
        margin: 0;
        margin-left: 9.2rem;
        height: 8rem;
        line-height: 8rem;
        color: #1E1E1E;
        font-size: 1.5rem;
        font-family: Open Sans, Open Sans;
        font-weight: 500;
    }
    .statusBox {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 6rem;
        text-align: center;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .statusBox {
        background-color: #43c054;
        width: 8rem;
        height: 8rem;
        box-sizing: border-box;
        .statusIco {
            color: #fff;
        }
    }
}
</style>