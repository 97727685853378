<template>
    <div style="height: 100%;"  class="isBlackBg">
        <NuxtPage :keepalive="{ include: ['chatroom'] }" />
        <van-dialog v-model:show="notVoteshow" :show-confirm-button="false">
            <div class="diamain">
                <div class="maincontent">Sorry, you need to sign up or log in to rate more users.</div>
                <div class="row-center" style="margin-top: 3.1rem">
                    <div class="signbtn" @click="rentosingup">SIGN UP</div>
                </div>
                <div class="row-center" style="margin-top: 1.6rem; margin-bottom: 2.5rem">
                    <span class="loginbtn" @click="rentologin"> LOG IN </span>
                </div>
            </div>
        </van-dialog>
        <!-- <div class="siteLoading" v-if="!userinforef"></div> -->
        <site-tip />
        <custom-dialog />
        <custom-tips></custom-tips>
        <add-home-screen-dialog v-if="isMobile"/>
        <rate-high-score-tip />
        <verify-email-dialog />
        <open-browse-tip />
        <div class="preloadImgs">
            <img src="~/assets/img/ic_done.png" />
            <img src="~/assets/img/ic_cancel.png" />
            <img src="~/assets/img/ic_start.png" />
            <img src="~/assets/img/ic_switch.png" />
        </div>
    </div>
</template>

<script setup>
import { initSync, addLogSync, getMyInfoSync, regSync } from "/utils/api";
import { setMyInfo } from "/utils";
import useUser from "/store/user";
import { showLoadingToast, closeToast } from "vant";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import md5 from "md5";
const notVoteshow = useState("notVoteshow");
const userinfo = useCookie("userinfo");
const userinforef = useState("userinforef");
const runtimeConfig = useRuntimeConfig();
const isBwfilter = useCookie("isBwfilter");
//isBwfilter.value = ['bw', 'se', 'ch', 'ti', 'mee', 'ge', 'el', 'cl', 'tim', 'ele', 'am', 'ami', 're', 'my', 'up', 'mo', 'to', 'eleg', 'bws', 'ph', 'lo', 'rea', 'amif', 'bwc', 'be', 'sel', 'al', 'amiu', 'amis', 'bwv', 'at', 'fa', 'cha', 'char', 'toa', 'cla', 'elega', 'fas', 'att', 'ar', 'clas', 'amic', 'attr', 'mon', 'class', 'bea', 'bwm', 'fo', 'classy', 'hi', 'reali].includes(runtimeConfig?.public.engine)
isBwfilter.value = false
const onlyImgSite = useCookie("onlyImgSite");
onlyImgSite.value = ['bw', 'ho', 'ch', 'ti', 'mee', 'ge', 'el', 'cl', 'tim', 'ele', 'am', 'ami', 're', 'my', 'up', 'mo', 'to', 'eleg', 'bws', 'ph', 'lo', 'rea', 'amif', 'bwc', 'be', 'sel', 'al', 'amiu', 'amis', 'bwv', 'at', 'fa', 'cha', 'char', 'toa', 'cla', 'elega', 'fas', 'att', 'ar', 'clas', 'amic', 'attr', 'mon', 'class', 'bea', 'bwm', 'fo', 'classy', 'hi', 'reali', 'ra', 'classyr', 'ran'].includes(runtimeConfig?.public.engine)
const allowSelectFile = useCookie("allowSelectFile");
allowSelectFile.value = false
const hasMultiType = useCookie("hasMultiType");
hasMultiType.value = ['reali'].includes(runtimeConfig?.public.engine)
userinforef.value = userinfo.value;
const siteStyleType = useCookie("siteStyleType"); // 1默认风格, 2带围巾跟帽子
siteStyleType.value = 1
if (['ti', 'ch', 'mee', 'ge', 'ele', 'cl', 'tim', 'am', 'ami', 're', 'my', 'up', 'mo', 'to', 'rea', 'eleg', 'bws', 'ph', 'lo', 'el'].includes(runtimeConfig?.public.engine)) {
    siteStyleType.value = 2
}
const isAfricaSite = useCookie("isAfricaSite");
isAfricaSite.value = ['classy', 'ti', 'ele', 'tim', 'cl', 'att', 'toa', 'class', 'amif', 'amic', 'amiu', 'ra', 'bea', 'elega', 'am', 'rea', 'up', 'classyr', 'ran', 'mo'].includes(runtimeConfig?.public.engine)
watch(
    () => notVoteshow.value,
    (n) => {
        notVoteshow.value = n;
    }
);
const userStore = useUser();
const { gtag } = useGtag();
const router = useRouter();
const clickfollow = useState("clickfollow", () => 0);
// 是否为移动端
// const { data: isMobile } = await useFetch("/api/init");
const isMobile = useState("isMobile");
const isIos = useState("isIos");
const isSimulatenMobile = useState("isSimulatenMobile");
const refreshMyUserInfoLoading = useState("refreshMyUserInfoLoading");
refreshMyUserInfoLoading.value = false
const useSelectFileMode = useState("useSelectFileMode");
useSelectFileMode.value = false
onMounted(() => {
    isMobile.value =
        navigator?.appVersion.indexOf("Macintosh") === -1 && navigator?.appVersion.indexOf("Windows") === -1 ? 1 : 0;
    // isMobile.value = 0;
    isIos.value = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) ? true : false;
    let platformInfo = navigator && navigator.platform ? navigator.platform : ''
    if (
        platformInfo.indexOf('HP-UX') == 0 ||
        platformInfo.indexOf('Linux i686') == 0 ||
        platformInfo.indexOf('Linux armv7l') == 0 ||
        platformInfo.indexOf('Mac68K') == 0 ||
        platformInfo.indexOf('MacPPC') == 0 ||
        platformInfo.indexOf('Win16') == 0 ||
        platformInfo.indexOf('Win32') == 0 ||
        platformInfo.indexOf('Win64') == 0 ||
        platformInfo.indexOf('WinCE') == 0 ||
        platformInfo.indexOf('win32') == 0 ||
        platformInfo.indexOf('win64') == 0 ||
        platformInfo.indexOf('MacIntel') == 0
    ) {
        isSimulatenMobile.value = true
    } else {
        isSimulatenMobile.value = false
    }
    addLogSync({
        content: isMobile.value,
        act: "open",
    });
});
initSync();

//才进页面进行这注册游客模式
const initTrval = () => {
    const toast = showLoadingToast({
        duration: 0,
        forbidClick: true,
        // message: "loading...",
    });
    regSync({
        isVisitor: 1,
    })
        .then((res) => {
            closeToast();
            // localStorage.setItem("VisitorInformation", JSON.stringify(res.data));
            // localStorage.setItem("VisitorToken", res.token);
            localStorage.setItem("userToken", res.data.visitor_token);
            loginSuccess({
                userinfo: res.data,
                token: res.token,
                isshowToast: false,
                // act: 'signup',
                notto: true,
                // to: "/introduce",
            });
            userinforef.value = res?.data
        })
        .catch(() => {
            closeToast();
        });
};

// 存储个人信息
function updateStore(info, token) {
    userStore.myUserInfo = info;
    userStore.token = token;
}
const tokenCookie = useCookie("token");
// if (!tokenCookie.value) {
// 	router.replace({ path: "/login/phone-or-email/email", query: { isfromResetPwd: true } })
// }
// 初始化一些state
const whoCanList = useState("whoCanList", () => {
    return [
        {
            label: "Everyone",
        },
        {
            label: "Friends",
            desc: "Followers that you follow back",
        },
        {
            label: "Only me",
        },
    ];
});
const rentologin = () => {
    notVoteshow.value = false;
    router.push("/login");
};
const rentosingup = () => {
    notVoteshow.value = false;
    router.push("/login");
    const route = useRoute();
    if (route && route.name == "notLogInShare") {
        typeof gtag == 'function' && gtag("event", "Signup_Start_From_Shared_Photo");
        sessionStorage.setItem("Signup_From_Shared_Photo", 1);
    }
};
const setTid = () => {
    // 设置tid
    const route = useRoute();
    const tid =  useCookie("tid");
    if (route.query && route.query?.tid) {
        tid.value = route.query.tid
    }
}
const setGaid = () => {
    // 设置tid
    const route = useRoute();
    const gaid =  useCookie("gaid");
    if (route.query && route.query?.click_id) {
        gaid.value = route.query.click_id
    }
}
const setInviteCode = () => {
    // 设置邀请码
    const route = useRoute();
    const inviteCode =  useCookie("inviteCode");
    if (route.query && route.query?.inviteCode) {
        inviteCode.value = route.query.inviteCode
    }
}
const setUUID = () => {
    let UUID = localStorage && localStorage?.getItem ? localStorage.getItem('UUID') || '' : ''
    if (UUID) return
    try {
        // // Initialize the agent at application startup.
        // // If you're using an ad blocker or Brave/Firefox, this import will not work.
        // // Please use the NPM package instead: https://t.ly/ORyXk
        // const fpPromise = import('https://openfpcdn.io/fingerprintjs/v4')
        //     .then(FingerprintJS => FingerprintJS.load())

        // // Get the visitor identifier when you need it.
        // fpPromise
        // .then(fp => fp.get())
        // .then(result => {
        //     // This is the visitor identifier:
        //     localStorage && localStorage?.setItem && localStorage.setItem("UUID", result.visitorId);
        // })
        (async () => {
            const fp = await FingerprintJS.load({ screen_resolution: true });
            const result =await fp.get();
            if (result && result?.visitorId) {
                let newUUID = result.visitorId + new Date().getTime() + Math.ceil(Math.random() * 10000000).toString()
                localStorage && localStorage?.setItem && localStorage.setItem("UUID", newUUID);
            }
        })();
    } catch (err) {
        console.log('Set UUID Error:', err)
        try {
            let visitorId = md5(Math.ceil(Math.random() * 10000000).toString())
            if (typeof navigator != 'undefined' && typeof navigator.userAgent != 'undefined') {
                visitorId = md5(navigator.userAgent)
            }
            let newUUID = visitorId + new Date().getTime() + Math.ceil(Math.random() * 10000000).toString()
            localStorage && localStorage?.setItem && localStorage.setItem("UUID", newUUID);
        } catch (error) {
            console.log('Set UUID Error2:', error)
        }
    }
}
const initAddHomeScreenDialog = () => {
    let hideAddHomeScreenDialog = localStorage && localStorage.getItem ? localStorage.getItem('hideAddHomeScreenDialog') || 0 : 1
    if (hideAddHomeScreenDialog == 1 || !userinfo.value || !userinfo.value?.createtime) return
    let regDays = (new Date().getTime() - parseInt(userinfo.value.createtime.toString() + '000')) / (24 * 3600 * 1000)
    if (regDays > 1) {
        const addHomeScreenDialogData = useState('addHomeScreenDialogData')
        if (addHomeScreenDialogData.value != null) {
            addHomeScreenDialogData.value.show = true
        }
    }
}
const { engine, SERVER_PATH } = useRuntimeConfig().public;
const domain = SERVER_PATH.replace("/api/", "");
const setSiteThumbnail = () => {
    try {
        let img = document.createElement("img")
        img.src = `${domain}/${engine}/img/applogo.png`
        img.width = 0
        img.height = 0
        img.setAttribute('style', 'width: 0px; height: 0px; position: absolute')
        var firstChild = document.body.firstChild;
        document.body.insertBefore(img, firstChild);
    } catch (e) {
        console.log(e);
    }
}

const updateChatroomConfig = () => {
    getChatroomConfig().then((res) => {
        if (res && res.data && res.data.length > 0) {
            localStorage.setItem("chatrooms", JSON.stringify(res.data))
        }
    })
}

const setSelectFileMode = () => {
    // 设置tid
    const route = useRoute();
    if (route.query && route.query?.useSelectFileMode) {
        useSelectFileMode.value = parseInt(route.query?.useSelectFileMode) == 1 ? true : false
    }
}
onMounted(() => {
    setSelectFileMode()
    setSiteThumbnail()
    setTid()
    setGaid()
    setInviteCode()
    setUUID()
    initAddHomeScreenDialog()
    updateChatroomConfig()
    // if (!localStorage.getItem("userToken")) {
    //     initTrval();
    // }
    refreshMyUserInfoLoading.value = true
    if (userinfo?.value) {
        // 刷新登录信息
        getMyInfoSync().then((res) => {
            if (!res.data || !res.token) return;
            const userinfod = res?.data;
            const tokend = res?.token;
            userinforef.value = res?.data;
            setMyInfo({
                userinfo: userinfod,
                token: tokend,
            });
            // updateStore(userinfo, token);
        }).finally(()=>{
            refreshMyUserInfoLoading.value = false
        })
    }
    if (['ra'].includes(runtimeConfig?.public.engine)) {
        window.googleTranslateElementInit = function () {
            new google.translate.TranslateElement(
                {
                    pageLanguage: 'en',
                    // includedLanguages: 'en,de,fr,es,pt',
                    layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
                    autoDisplay: false
                },
                'google_translate_element'
            )
        }
    }
});
</script>

<style lang="scss">
body {
    margin: 0 !important;
}

:root {
    --el-color-primary: #fe2c55 !important;
}

.contain-box {
    width: calc(100vw - 32px);
    padding: 1.6rem;
}
.diamain {
    // width: 33.5rem;
    // height: 22.1rem;
    .maincontent {
        background: #ffffff;
        opacity: 1;
        margin-top: 3.8rem;
        padding: 0 1.6rem;
        text-align: center;
        font-family: "Poppins-Regular";
    }
    .signbtn {
        width: 25rem;
        height: 4rem;
        background: #f67128;
        border-radius: 18.7rem 18.7rem 18.7rem 18.7rem;
        opacity: 1;
        color: #fff;
        border: 0.1rem solid #f67128;
        text-align: center;

        font-size: 1.6rem;
        font-family: "Poppins-Regular";
        font-weight: 500;
        line-height: 4rem;
    }
    .loginbtn {
        width: 5.2rem;
        height: 2.6rem;
        font-size: 1.6rem;
        font-family: "Poppins-Regular";
        font-weight: 500;
        color: #0f0f0f;
        line-height: 2.6rem;
        border-bottom: 0.1rem solid #000;
    }
}
// .siteLoading {
//     position: fixed;
//     left: 0;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     background-color: #fff;
//     margin: auto;
//     background-image: url('assets/img/logo2.png');
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: 15rem auto;
//     z-index: 999999;
// }
@media screen and (max-width: 768px) {
    .isBlackBg{
        background-color: #0f0f0f;
    }
}
.preloadImgs {
    width: 0;
    height: 0;
    overflow: hidden;
    img {
        width: 0;
        height: 0;
        opacity: 0;
    }
}
</style>
