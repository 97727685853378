export default defineNuxtPlugin((nuxtApp) => {
    const { GAID, facebookId, engine } = useRuntimeConfig().public;
    let script = [];
    if (GAID) {
        function gtag() {
            window.dataLayer.push(arguments);
        }

        window.dataLayer = window.dataLayer || [];

        gtag("js", new Date());
        gtag("config", GAID);
        script = [
            {
                hid: "ga4",
                src: `https://www.googletagmanager.com/gtag/js?id=${GAID}`,
                async: true,
            },
            {
                hid: "ga4-config",
                innerHTML: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GAID}'); // 使用你的 Measurement ID
                  `,
                type: "text/javascript",
                // charset: "utf-8",
            },
        ];
    }
    if (facebookId) {
        script.push({
            innerHTML: `
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '${facebookId}');
                    fbq('track', 'PageView');
                  `,
            type: "text/javascript",
            // charset: "utf-8",
        });
    }
    if (['ra'].includes(engine)) {
        script.push({
            hid: "googtrans",
            src: `https://translate.google.com/translate_a/element.js?&cb=googleTranslateElementInit`,
            async: true,
        });
    }
    // script.push({
    //     innerHTML:'',
    //     src: 'https://cdnjs.cloudflare.com/ajax/libs/tracking.js/1.1.3/tracking-min.js',
    //     type: "text/javascript"
    // })
    // script.push({
    //     innerHTML:'',
    //     src: 'https://cdnjs.cloudflare.com/ajax/libs/tracking.js/1.1.3/data/face-min.js',
    //     type: "text/javascript"
    // })
    if (script.length) {
        useHead({
            script: script,
        });
    }
});
