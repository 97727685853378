<template>
    <div class="AddHomeScreenDialog" v-if="addHomeScreenDialogData.show && initComplete">
        <div class="bg" @click="closeDialog"></div>
        <div class="dialogWarp">
            <div class="closeIco" @click="closeDialog">
                <IconClose />
            </div>
            <template v-if="showSafariTip">
                <div class="title">Add {{ appName }} icon to your iPhone Home Screen for the quickest access</div>
                <div class="des">
                    <div class="item">1. Tab <span class="ico1"><img src="~/assets/img/popup_ic_appleshare@2x.png" /></span> in the menu bar.</div>
                    <div class="item">2. Scroll down the list of options, then tap Add to Home Screen.</div>
                    <div class="item">If you don't see Add to Home Screen, you can add it by scrolling to the bottom of the list, tapping Edit Actions, and then tapping <span class="ico2"><img src="~/assets/img/popup_ic_appleadd@2x.png" /></span> Add to Home Screen.</div>
                </div>
            </template>
            <template v-else>
                <div class="title">Add {{ appName }} shortcut to your phone's Home Screen for quick access</div>
                <div class="des">
                    <div class="item">1. Tap the 3 dots menu icon in the upper right-hand corner. </div>
                    <div class="item">2. Tap Add to Home screen. </div>
                    <div class="item">3. The {{ appName }} website icon will be displayed as a shortcut on the home screen - just like an app.</div>
                </div>
            </template>
            <div class="btn" @click="closeDialog">OK, GOT IT</div>
        </div>
    </div>
</template>

<script setup>
const { appName } = useRuntimeConfig().public;
const detectBrowser = () => {
    const userAgent = navigator && navigator?.userAgent ? navigator.userAgent : '';
    if (userAgent.indexOf("Chrome")!== -1 && userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Edg") === -1) {
      return "Google Chrome"; // Chrome
    } else if (userAgent.indexOf("Firefox") !== -1) {
      return "Mozilla Firefox"; // Firefox
    } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1 && userAgent.indexOf("Edge") === -1) {
      return "Apple Safari"; // Safari
    } else if (userAgent.indexOf("Edg") !== -1) {
      return "Microsoft Edge"; // Edge
    } else {
      return "Unknown browser"; // 其他浏览器...（可根据自己需要确定是否新增其他浏览器的判断）
    }
}
const initComplete = ref(false);
const showSafariTip = ref(false);
const addHomeScreenDialogData = useState('addHomeScreenDialogData', ()=>{
    return {
        show: false,
    }
});
const closeDialog = () => {
    addHomeScreenDialogData.value = {
        show: false
    }
    localStorage.setItem('hideAddHomeScreenDialog', 1)
}
onMounted(()=>{
    showSafariTip.value = detectBrowser().indexOf('Safari') > -1 && /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)
    initComplete.value = true
})
</script>
<style scoped lang="scss">
.AddHomeScreenDialog {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 999999;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .dialogWarp {
        background: #2E2E2E;
        width: calc(100% - 3.2rem);
        max-width: 35rem;
        margin: 0 auto;
        border-radius: 0.4rem;
        box-sizing: border-box;
        position: relative;
    }
    .title {
        font-family: Poppins-SemiBold;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.3rem;
        text-align: center;
        text-transform: uppercase;
        margin: 2.4rem 1.6rem 0;
        word-break: break-word;
    }
    .des {
        padding-top: 1.9rem;
        margin: 0 1.6rem 0;
        .item {
            font-family: Poppins-Regular;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2.6rem;
            letter-spacing: 0.03rem;
            margin-top: 0.9rem;
            word-break: break-word;
        }
    }
    .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 0.1rem solid #F1F1F1;
        font-family: Poppins-Medium;
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2.1rem;
        text-align: center;
        text-transform: uppercase;
        width: 25rem;
        color: $mainColor;
        margin: auto;
        margin-top: 1.6rem;
        margin-bottom: 3.2rem;
        height: 4rem;
        background: $mainftColor;
        border-radius: 18.7rem;
    }
    .ico1,
    .ico2 {
        position: relative;
    }
    .ico1 {
        padding-left: 1.9rem;
    }
    .ico1 img {
        display: block;
        position: absolute;
        left: 0;
        top: -0.4rem;
        width: 1.5rem;
        height: 2rem;
        pointer-events: none;
    }
    .ico2 {
        padding-left: 2.1rem;
    }
    .ico2 img {
        display: block;
        position: absolute;
        left: 0;
        top: 0.1rem;
        width: 1.8rem;
        height: 1.8rem;
        pointer-events: none;
    }
    .closeIco {
        position: absolute;
        right: 0.7rem;
        top: 0.7rem;
        width: 1.7rem;
        height: 1.7rem;
        svg {
            width: 1.7rem;
            height: 1.7rem;
            color: #888;
        }
    }
}
</style>