import has from "lodash/has";
import md5 from "md5";
import merge from "lodash/merge";
import toUpper from "lodash/toUpper";
import { uploadtokenSync } from "./api";
const clearAllCookies = () => {
    // 获取当前所有的 Cookie
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        if (cookies[i].indexOf('tid=') == -1 && cookies[i].indexOf('isBwfilter=') == -1 && cookies[i].indexOf('onlyImgSite=') == -1 && cookies[i].indexOf('siteStyleType=') == -1 && cookies[i].indexOf('gaid=') == -1 && cookies[i].indexOf('allowSelectFile=') == -1 && cookies[i].indexOf('hasMultiType=') == -1 && cookies[i].indexOf('inviteCode=') == -1 && cookies[i].indexOf('isAfricaSite=') == -1 && cookies[i].indexOf('googtrans=') == -1) { //不清除tid
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    }
};
const autoLoginOut = () => {
    typeof localStorage != 'undefined' && localStorage?.removeItem ? localStorage.removeItem('userToken') || '' : ''
    clearAllCookies();
    const route = useRoute();
    const userinfo = useCookie("userinfo");
    const userinforef = useState("userinforef");
    const notLoginPaths = ['/', '/auth/create-photo', '/auth/create-email', '/login/with-signup/email', '/auth/forgotPassword', '/auth/create-verify', '/auth/create-phone', '/login', '/register', '/auth/create-info']
    const notAutoJumpPaths = ['/setting/terms-of-service', '/setting/privacy-policy', '/notLogInShare', '/profile', '/foryou','/searchTid', '/resetPwd', '/about-us']
    let checkPaths = notLoginPaths.concat(notAutoJumpPaths)
    if (route && route?.path && !checkPaths.includes(route.path)) {
        navigateTo(`/profile`);
    } else if (route.path == '/profile' && userinforef?.value) {
        userinforef.value = userinfo.value
        window.location.reload()
    }
}
//import includes from 'lodash/includes';
//import startsWith from 'lodash/startsWith';

const fetch = async (args) => {
    const runtimeConfig = useRuntimeConfig();
    const url = runtimeConfig?.public.SERVER_PATH + args.url;
    const UUID = typeof localStorage != 'undefined' && localStorage?.getItem ? localStorage.getItem('UUID') || '' : ''
    let data = args?.data || {};
    let method = args?.method || "get";
    const event = args?.event;
    method = toUpper(method);

    let showErrMsg = true;
    if (has(args, "showErrMsg")) {
        showErrMsg = args.showErrMsg;
    }

    let headers = {};
    // csrf-token
    const noSafeMethod = !csrfSafeMethod(method);
    const csrfToken = useCookie("csrfToken");
    if (noSafeMethod) {
        headers = useRequestHeaders(["cookie"]); // 我们可以使用`useRequestHeaders``从服务器端访问并代理cookie到api。
        headers["x-csrf-token"] = csrfToken.value;
    }

    // 权鉴 - 是否需要权鉴，由后端控制
    const token = useCookie("token");
    if (token.value && args.url.indexOf("user/visitorLogin") === -1) {
        headers.Authorization = "Bearer " + token.value;
    }
    const currentUserType = useCookie("currentUserType")
    const hasMultiType = useCookie("hasMultiType")
    if (currentUserType.value !== undefined && hasMultiType.value) {
        headers["isSelfie"] = currentUserType.value
    }
    let content = {
        headers,
        method,
        params: args?.params,
    };

    if (method == "GET") {
        content.params = adornParams(data); // 对于GET，特殊处理
    } else {
        content.body = data;
    }
    if (!UUID) {
        try {
            let visitorId = md5(Math.ceil(Math.random() * 10000000).toString())
            if (typeof navigator != 'undefined' && typeof navigator.userAgent != 'undefined') {
                visitorId = md5(navigator.userAgent)
            }
            let newUUID = visitorId + new Date().getTime() + Math.ceil(Math.random() * 10000000).toString()
            localStorage && localStorage?.setItem && localStorage.setItem("UUID", newUUID);
        } catch (error) {
            console.log('Set UUID Error2:', error)
        }
    }
    UUID ? (headers['UUID'] = UUID) : ''

    return new Promise((resolve, reject) => {
        $fetch(url, content)
            .then((data) => {
                if (data?.code == 1000) {
                    // 请求正常、返回正常
                    resolve(data);
                } else if (data?.code == 4001) {
                    reject(data);
                    autoLoginOut()
                } else if (data?.code == 1002) {
                    reject(data);
                    showDialog({
                        message: "We are unable to process your request. Please try again.",
                        confirmButtonText: "OK",
                    });
                } else {
                    // 请求正常、返回错误
                    reject(data);
                }
                //autoLoginOut()
            })
            .catch((err) => {
                // 权鉴问题 - 并非所有页面都要登录才能访问
                // 权鉴问题 - 并非所有页面都要登录才能访问
                if (err.status == 401) {
                    autoLoginOut()
                    // const userinfo = useCookie("userinfo");
                    // const userinforef = useState("userinforef");
                    // const userToken = localStorage.getItem("userToken");
                    // if (!userinfo.value || userinfo.value?.visitor_token) {
                    //     uploadtokenSync({ visitorToken: userToken }).then((res) => {
                    //         if (res?.token) {
                    //             token.value = res?.token;
                    //             userinfo.value = res?.data;
                    //             userinforef.value = res?.data;
                    //         } else {
                    //             localStorage.removeItem("userToken");
                    //             //clearAllCookies();
                    //             autoLoginOut()
                    //         }
                    //     });
                    // }
                    // const userinfo = useCookie("userinfo");
                    // const userinforef = useState("userinforef");
                    // const userToken = localStorage.getItem("userToken");
                    // uploadtokenSync({ visitorToken: userToken }).then((res) => {
                    //     if (res?.token) {
                    //         token.value = res?.token;
                    //         userinfo.value = res?.data;
                    //         userinforef.value = res?.data;
                    //     } else {
                    //         localStorage.removeItem("userToken");
                    //         clearAllCookies();
                    //     }
                    // });
                    // logout({'isJump': 0});
                    // showDialog({
                    //   message: err.msg ? err.msg : "Request failed, please refresh.",
                    //   confirmButtonText: "OK",
                    // }).then(() => {
                    //   // clearAllCookies();
                    // });
                    // location.reload(true);
                } else if (err.status == 499) {
                    console.log(err)
                } else {
                    showDialog({
                        message: err.msg ? err.msg : "We are unable to process your request. Please try again.",
                        confirmButtonText: "OK",
                    });
                }
                reject(err);
            });
    });
};

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
const adornParams = (params = {}, openDefultParams = true) => {
    let defaults = {};
    return openDefultParams ? merge(defaults, params) : params;
};

// 无需CSRF的method
const csrfSafeMethod = (method) => {
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test();
};

export default new (class Http {
    get(args) {
        args.method = "get";
        return fetch(args);
    }

    post(args) {
        args.method = "post";
        return fetch(args);
    }

    put(args) {
        args.method = "put";
        return fetch(args);
    }

    delete(args) {
        args.method = "delete";
        return fetch(args);
    }
})();
