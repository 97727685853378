import Http from "@/utils/http";
import { getFingerprint } from "/utils";

// 获取oss的签名
export function getOssSignSync(data) {
    return Http.get({
        url: "v1/oss/getOssSign",
        data,
    });
}

//--------------------------------------------账号--------------------------------------------//

// 初始化
export function initSync() {
    return Http.get({
        url: "v1/user/init",
    });
}

// 初始化 - post
export function initPostSync(data) {
    return Http.post({
        url: "v1/user/initPost",
        data,
    });
}

// 埋点
export async function addLogSync(data) {
    if (process.client) {
        data.browser = await getFingerprint();
        return Http.post({
            url: "v1/user/addLog",
            data,
        });
    }
}

/**
 * 查询号码、邮箱是否已注册
 * @param {*} type email | phone
 * @param {*} value
 */
export function userExistsSync(data) {
    return Http.get({
        url: "v1/user/userExists",
        data,
    });
}

/**
 * 获取邮箱验证码
 * @param {*} email 邮箱
 */
export function getCodeSync(data) {
    return Http.post({
        url: "v1/user/getCode",
        data,
    });
}

/**
 * 上传照片或者视频到说S3服务器
 * @param {} type =1 是图片 type = 2 是视频
 */
export function userUpload(data) {
    return Http.post({
        url: "v1/common/upload",
        data,
    });
}

export function sendFeedBack(data) {
    return Http.post({
        url: "v1/feedback",
        data,
    });
}
/**
 * 校验邮箱验证码
 * @param {*} email 邮箱
 * @param {*} code 6位数验证码
 */
export function checkCodeSync(data) {
    return Http.post({
        url: "v1/user/checkCode",
        data,
    });
}

/**
 * 注册
 * @param {*} email 邮箱
 * @param {*} code 6位数验证码
 */
export function regSync(data) {
    return Http.post({
        url: "v1/user/reg",
        data,
    });
}

/**
 * 登录
 * @param {} email 它可能是用户名
 * @param {} phone 区号+电话号码
 * @param {*} pwd 密码
 */
export function loginSync(data) {
    return Http.post({
        url: "v1/user/login",
        data,
    });
}

/**
 * 获取本人信息
 */
export function getMyInfoSync(data) {
    let requestData = data || {}
    const currentUserType = useCookie("currentUserType")
    const hasMultiType = useCookie("hasMultiType")
    if (hasMultiType.value) {
        requestData.isSelfie = currentUserType.value == undefined ? 1 : [0, 1].includes(parseInt(currentUserType.value)) ? currentUserType.value : 1
    }
    return Http.get({
        url: "v1/user/getMyInfo",
        data: requestData,
    });
}

/**
 * 提示新消息
 */
export function getNotificationNum() {
    return Http.get({
        url: "v1/notification/newStatus"
    });
}

/**
 * 已读新消息
 */
export function notificationRead(data) {
    return Http.post({
        url: "v1/notification/read",
        data
    });
}

/**
 * 编辑用户（本人）资料
 * @param {} username 用户名
 * @param {} bio 个人介绍
 */
export function userUpdateSync(data) {
    return Http.put({
        url: "v1/user/update",
        data,
    });
}

/**
 * 编辑用户（本人）隐私设置
 */
export function updatePrivacySync(data) {
    return Http.put({
        url: "v1/user/updatePrivacy",
        data,
    });
}

/**
 * 删除账户
 * @param {} reason 注销原因
 * @param {*} pwd 密码
 */
export function delAccountByPwdSync(data) {
    return Http.delete({
        url: "v1/user/delAccountByPwd",
        data,
    });
}

/**
 * 首次设置Username
 * @param {*} username username，必传，内容可空，为空时，后端自动生成一个随机名
 */
export function setUsernameSync(data) {
    return Http.put({
        url: "v1/user/setUsername",
        data,
    });
}

/**
 * 修改Username
 * @param {*} username username，必传，内容不可空
 */
export function changeUsernameSync(data) {
    return Http.put({
        url: "v1/user/changeUsername",
        data,
    });
}

/**
 * 查询关注 | 粉丝列表
 * @param {*} type follow | follower
 * @param {*} lowname 小写用户名
 * @param {*} page 页码
 */
export function getFollowListSync(data) {
    return Http.get({
        url: "v1/user/getFollowList",
        data,
    });
}
/**
 * 查询likes | 喜欢列表
 * @param {*} type follow | follower
 * @param {*} lowname 小写用户名
 * @param {*} page 页码
 */
export function getLikeListSync(data) {
    return Http.get({
        url: "v1/user/getLikeList",
        data,
    });
}

//--------------------------------------------post--------------------------------------------//

/**
 * 发布视频
 * @param {*} username username，必传，内容不可空
 */
export function postSync(data) {
    return Http.post({
        url: "v1/post/post",
        data,
    });
}

/**
 * 获取我的post - 分页 - 基本信息
 * @param {} page 页码 - 不传则为1
 */
export function getMyPostSync(data) {
    return Http.get({
        url: "v1/post/getMyPost",
        data,
    });
}

/**
 * 获取我的点赞
 * @param {} page 页码 - 不传则为1
 */
export function getMyLikeSync(data) {
    return Http.get({
        url: "v1/post/getMyLike",
        data,
    });
}

/**
 * 获取、补充post的额外信息
 * @param {} requestId 请求id - 用于获取存在上一个请求的视频id数据
 */
export function getPostExtraSync(data) {
    return Http.get({
        url: "v1/post/getPostExtra",
        data,
    });
}

/**
 * 更新我的post
 * @param {*} postId 作品Id
 * @param {*} userId 作者Id，用于权鉴
 */
export function updatePostSync(data) {
    return Http.put({
        url: "v1/post/updatePost",
        data,
    });
}

/**
 * 更新我的post - 置顶
 * @param {*} postId 作品Id
 * @param {*} userId 作者Id，用于权鉴
 */
export function togglePinSync(data) {
    return Http.put({
        url: "v1/post/togglePin",
        data,
    });
}

/**
 * 删除我的post
 * @param {*} postId 作品Id
 * @param {*} userId 作者Id，用于权鉴
 */
export function delPostSync(data) {
    return Http.delete({
        url: "v1/post/delPost",
        data,
    });
}

/**
 * 点赞 | 取消
 * @param {*} postId 作品Id
 * @param {} vote 只能投票，不能取消投票
 */
export function voteVideoSync(data) {
    return Http.post({
        url: "v1/vote/post",
        data,
    });
}

/**
 * 点赞 | 取消
 * @param {*} postId 作品Id
 * @param {} like 是否只喜欢，而不是切换，默认否。如果为是，则是只点赞、不取消点赞
 */
export function toggleLikeSync(data) {
    return Http.post({
        url: "v1/post/toggleLike",
        data,
    });
}
export function voteSync(data) {
    return Http.post({
        url: "v1/vote/score",
        data,
    });
}
/**
 * 关注 | 取消
 * @param {*} lowname 小写username | 为什么不是userId：避免传输userId，而username是公开的
 */
export function toggleFollowSync(data) {
    return Http.post({
        url: "v1/user/toggleFollow",
        data,
    });
}

/**
 * 举报
 * @param {*} targetId 目标Id
 * @param {*} type post | user
 * @param {*} reason 原因
 */
export function reportSync(data) {
    return Http.post({
        url: "v1/post/report",
        data,
    });
}

//--------------------------------------------comment--------------------------------------------//

/**
 * 查询视频的直接评论
 * @param {*} postId 作品Id
 * @param {} page 页码 - 不传则为1
 */
export function getCommentPostSync(data) {
    return Http.get({
        url: "v1/comment/getCommentPost",
        data,
    });
}

/**
 * 发布视频的直接评论
 * @param {*} postId 作品Id
 * @param {*} content 评论内容
 */
export function sendCommentSync(data) {
    return Http.post({
        url: "v1/comment/send",
        data,
    });
}

/**
 * 发布评论的回复
 * @param {*} cId 评论的Id
 * @param {*} content 回复的内容
 */
export function sendReplySync(data) {
    return Http.post({
        url: "v1/comment/reply",
        data,
    });
}

/**
 * 查询评论的回复
 * @param {*} cId 评论的Id
 * @param {*} page 页码
 */
export function getReplySync(data) {
    return Http.get({
        url: "v1/comment/getReply",
        data,
    });
}

/**
 * 切换 - 点赞评论
 * @param {*} level 1 | 2 - 直接评论 | 回复评论
 * @param {*} cId 目标评论的Id
 */
export function likeCommentSync(data) {
    return Http.post({
        url: "v1/comment/like",
        data,
    });
}

/**
 * 查询首页的视频
 * @param {*} type foryou | follow
 */
export function getHomeListSync(data) {
    return Http.post({
        url: "v1/post/getHomeList",
        data,
    });
}

/**
 * 查询用户主页的信息
 * @param {*} lowname 小写的username
 */
export function getUserPageSync(data) {
    return Http.post({
        url: "v1/user/getUserPage",
        data,
    });
}

/**
 * 获取 Discover 中的热门内容
 * @param {*} type user | sound | tag
 */
export function getHotSync(data) {
    return Http.get({
        url: "v1/discover/getHot",
        data,
    });
}

/**
 * 获取 Notifications 中的likes
 */
export function getNotificationLikes(data) {
    return Http.get({
        url: "v1/notification/likes",
        data,
    });
}

/**
 * 获取 Notifications 中的Comments
 */
export function getNotificationComments(data) {
    return Http.get({
        url: "v1/notification/comments",
        data,
    });
}

/**
 * 获取 Notifications 中的followers
 */
export function getNotificationFollowers(data) {
    return Http.get({
        url: "v1/notification/followers",
        data,
    });
}

/**
 * 获取重置密码链接的接口
 * @param {*} email
 */
export function getResetPasswordLinkSync(data) {
    return Http.post({
        url: "v1/user/password/reset/link",
        data,
    });
}

/**
 * 输入新密码提交的接口
 * @param {*} email
 */
export function getPasswordResetSync(data) {
    return Http.post({
        url: "v1/user/password/reset",
        data,
    });
}
/**
 * 查询是否能够传照片的接口
 * @param {*} email
 */
export function checkCanUploadSync(data) {
    return Http.post({
        url: "v1/post/checkCanUpload",
        data,
    });
}
/**
 * 更新token
 * @param {*} email
 */
export function uploadtokenSync(data) {
    return Http.post({
        url: "v1/user/visitorLogin",
        data,
    });
}
/**
 * 多维度点赞
 * @param {*} profId 用户ID
 * @param {*} type 维度ID 1 - expression, 2 - hairStyle, 3 - outfit
 * @param {*} status 状态 0 - 未点赞, 2 - like, 3 - dislike
 */
export function dimensionScore(data) {
    return Http.post({
        url: "v1/vote/evaluation/report",
        data,
    });
}

export function getTidStatistic(data) {
    return Http.get({
        url: "v1/tid/statistic",
        data,
    });
}

/**
 * 获取账户预留推广打款银行信息
 * @param {*} 无参数
 */
export function getWithdrawals(data) {
    return Http.get({
        url: "v1/invite/get/withdrawals",
        data,
    });
}

/**
 * 获取账户预留推广打款银行信息
 * @param {method} 1- wise, 2 - cash app
 * @param {type} 1 - person
 * @param {firstName}
 * @param {lastName}
 * @param {email}
 * @param {countryId}
 */

export function updateWithdrawals(data) {
    return Http.post({
        url: "v1/invite/update/withdrawals",
        data,
    });
}

/**
 * 获取国家选项列表
 * @param {*} 无参数
 */
export function getCountries(data) {
    return Http.get({
        url: "v1/common/get/countries",
        data,
    });
}

/**
 * 获取视频分享封面
 * @param {coverUrl}
 * @param {postId}
 */
export function postShareCover(data) {
    return Http.post({
        url: "v1/post/shareCover",
        data,
    });
}

/**
 * 获取高评分弹窗提示信息
 * @param {*} 无参数
 */
export function postNewScore(data) {
    return Http.get({
        url: "v1/post/newScore",
        data,
    });
}

/**
 * 获取验证邮箱链接
 * @param {*} 无参数
 */
export function sendVerifyEmailLink(data) {
    return Http.post({
        url: "v1/user/verify_email/link",
        data,
    });
}

/**
 * 验证邮箱
 * @param {key} 验证key
 */
export function verifyEmail(data) {
    return Http.post({
        url: "v1/user/email/verify",
        data,
    });
}

/**
 * 获取rate me 列表
 * @param {profId} profile id
 */
export function getRateMeList(data) {
    return Http.get({
        url: "v1/user/getRateMeList",
        data,
    });
}

/**
 * 获取聊天室历史记录
 * @param {roomId} room id
 * @param {page} 分页页码
 * @param {limit} 分页大小
 */
export function getChatroomHistories(data) {
    return Http.get({
        url: "v1/chatroom/histories",
        data,
    });
}

/**
 * 获取所有聊天室配置
 */
export function getChatroomConfig(data) {
    return Http.get({
        url: "v1/chatroom/config",
        data,
    })
}

/**
 * 获取排行榜列表
 * @param {state} 周    
 * @param {country} 国家
 * @param {gender} 性别
 * @param {type} 
 */
export function getRankingList(data) {
    return Http.get({
        url: "v1/ranking/list",
        data,
    })
}

/**
 * 获取排行榜列表
 * @param {profId} 用户id
 */
export function getRankingInfo(data) {
    return Http.get({
        url: "v1/ranking/info",
        data,
    })
}

/**
 * 获取电话验证码
 * @param {phone} 电话号码
 */
export function getPhoneVerifyCode(data) {
    return Http.post({
        url: "v1/user/phone/send_code",
        data,
    })
}

/**
 * 获取电话验证码
 * @param {phone} 电话号码
 * @param {code} 验证码
 */
export function verifyPhone(data) {
    return Http.post({
        url: "v1/user/phone/verify",
        data,
    })
}

/**
 * 获取邮箱验证码
 * @param {email} 邮箱
 */
export function getEmailVerifyCode(data) {
    return Http.post({
        url: "v1/user/email/send_code",
        data,
    })
}

/**
 * 验证邮箱
 * @param {email} 邮箱
 * @param {code} 验证码
 */
export function verifyEmailCode(data) {
    return Http.post({
        url: "v1/user/email/verify_code",
        data,
    })
}

/**
 * 获取blog列表
 * @param {page} 页码
 * @param {limit} 每页返回多少数据
 * @param {commentsCnt} blog列表返回多少条comments
 */
export function getBlogList(data) {
    return Http.get({
        url: "v1/blog/list",
        data,
    })
}

/**
 * 点赞blog
 * @param {blogId} blogId
 * @param {type} 1 like, 0 unlike
 */
export function blogLike(data) {
    return Http.post({
        url: "v1/blog/like",
        data,
    })
}

/**
 * 点赞blog
 * @param {blogId} blogId
 * @param {page} 页码
 * @param {limit} 每页返回多少数据
 */
export function getBlogCommentList(data) {
    return Http.get({
        url: "v1/blog/comment/list",
        data,
    })
}

/**
 * 点赞blog
 * @param {blogId} blogId
 * @param {content} 评论内容
 */
export function blogCommentPost(data) {
    return Http.post({
        url: "v1/blog/comment/post",
        data,
    })
}