import { default as about_45ustxeYczLJftMeta } from "/usr/local/www/1000real-nuxt-pre/pages/about-us.vue?macro=true";
import { default as edit_45biofGg3qd43x2Meta } from "/usr/local/www/1000real-nuxt-pre/pages/account/edit-bio.vue?macro=true";
import { default as edit_45namec1nyjI1EHKMeta } from "/usr/local/www/1000real-nuxt-pre/pages/account/edit-name.vue?macro=true";
import { default as edit_45profileJwaNPsYmQmMeta } from "/usr/local/www/1000real-nuxt-pre/pages/account/edit-profile.vue?macro=true";
import { default as edit_45usernameKnl7e10ak2Meta } from "/usr/local/www/1000real-nuxt-pre/pages/account/edit-username.vue?macro=true";
import { default as animationDhSGehUlH4Meta } from "/usr/local/www/1000real-nuxt-pre/pages/animation.vue?macro=true";
import { default as creat_45genderu7EaY1AQuUMeta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/creat-gender.vue?macro=true";
import { default as create_45emailzF7Yq2LGxGMeta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/create-email.vue?macro=true";
import { default as create_45infokImBK35jX7Meta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/create-info.vue?macro=true";
import { default as create_45phoneTMRuKnyWNUMeta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/create-phone.vue?macro=true";
import { default as create_45photoCIc3F9FFS6Meta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/create-photo.vue?macro=true";
import { default as create_45userAndBirthKpMkBlY48iMeta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/create-userAndBirth.vue?macro=true";
import { default as create_45verifyxqr3XLl7liMeta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/create-verify.vue?macro=true";
import { default as digit_45code0UiXyEg24XMeta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/digit-code.vue?macro=true";
import { default as forgotPasswordT6JNI1AjC3Meta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/forgotPassword.vue?macro=true";
import { default as indexpw1hSDKUDxMeta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/index.vue?macro=true";
import { default as phone_45or_45emailsEVn84zpAqMeta } from "/usr/local/www/1000real-nuxt-pre/pages/auth/phone-or-email.vue?macro=true";
import { default as chatXLakwlrUaCMeta } from "/usr/local/www/1000real-nuxt-pre/pages/chat.vue?macro=true";
import { default as chatroom_45listP5cnqgLa2NMeta } from "/usr/local/www/1000real-nuxt-pre/pages/chatroom-list.vue?macro=true";
import { default as chatroomoCxNx63pEyMeta } from "/usr/local/www/1000real-nuxt-pre/pages/chatroom.vue?macro=true";
import { default as community_45guidelinestkdzoebPsSMeta } from "/usr/local/www/1000real-nuxt-pre/pages/community-guidelines.vue?macro=true";
import { default as communitydSvfwGeIegMeta } from "/usr/local/www/1000real-nuxt-pre/pages/community.vue?macro=true";
import { default as discoverbH7nGgrWvvMeta } from "/usr/local/www/1000real-nuxt-pre/pages/discover.vue?macro=true";
import { default as editBankInfo7DVN8g3EnJMeta } from "/usr/local/www/1000real-nuxt-pre/pages/editBankInfo.vue?macro=true";
import { default as feedbackR8nHCcDSdEMeta } from "/usr/local/www/1000real-nuxt-pre/pages/feedback.vue?macro=true";
import { default as follow_45list1H7d99OS8mMeta } from "/usr/local/www/1000real-nuxt-pre/pages/follow-list.vue?macro=true";
import { default as foryoudVw71Uo2sDMeta } from "/usr/local/www/1000real-nuxt-pre/pages/foryou.vue?macro=true";
import { default as add_45friendcMtQesKCnvMeta } from "/usr/local/www/1000real-nuxt-pre/pages/friend/add-friend.vue?macro=true";
import { default as indexdlzrm2lOILMeta } from "/usr/local/www/1000real-nuxt-pre/pages/friend/index.vue?macro=true";
import { default as inboxQtsIOZQv2sMeta } from "/usr/local/www/1000real-nuxt-pre/pages/inbox.vue?macro=true";
import { default as indextXwaCr5piNMeta } from "/usr/local/www/1000real-nuxt-pre/pages/index.vue?macro=true";
import { default as invite_45homel81j7WEFByMeta } from "/usr/local/www/1000real-nuxt-pre/pages/invite-home.vue?macro=true";
import { default as invitepF6W5AGcYaMeta } from "/usr/local/www/1000real-nuxt-pre/pages/invite.vue?macro=true";
import { default as login5suwKbkZy5Meta } from "/usr/local/www/1000real-nuxt-pre/pages/login.vue?macro=true";
import { default as myScoreoYtKMg4mJOMeta } from "/usr/local/www/1000real-nuxt-pre/pages/myScore.vue?macro=true";
import { default as notLogInShareGSa2nHFSrNMeta } from "/usr/local/www/1000real-nuxt-pre/pages/notLogInShare.vue?macro=true";
import { default as post_45previewWdwcYDVB54Meta } from "/usr/local/www/1000real-nuxt-pre/pages/post-preview.vue?macro=true";
import { default as post84Ws3ZzI2hMeta } from "/usr/local/www/1000real-nuxt-pre/pages/post.vue?macro=true";
import { default as profileRqEoFjlEspMeta } from "/usr/local/www/1000real-nuxt-pre/pages/profile.vue?macro=true";
import { default as rankingsN9td36kjFZMeta } from "/usr/local/www/1000real-nuxt-pre/pages/rankings.vue?macro=true";
import { default as registerobOAatp0gHMeta } from "/usr/local/www/1000real-nuxt-pre/pages/register.vue?macro=true";
import { default as resetPwdme7ZY9CCynMeta } from "/usr/local/www/1000real-nuxt-pre/pages/resetPwd.vue?macro=true";
import { default as searchaUelDVM4GkMeta } from "/usr/local/www/1000real-nuxt-pre/pages/search.vue?macro=true";
import { default as searchTidAOPrFqhW7KMeta } from "/usr/local/www/1000real-nuxt-pre/pages/searchTid.vue?macro=true";
import { default as accountt0aeXSzlyKMeta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/account.vue?macro=true";
import { default as contact_45us58hiPpkIp2Meta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/contact-us.vue?macro=true";
import { default as copyright_45policyJlY4vEG2dlMeta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/copyright-policy.vue?macro=true";
import { default as delete_45account4WfrF0v48oMeta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/delete-account.vue?macro=true";
import { default as delete_45noteezgz5qXHR5Meta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/delete-note.vue?macro=true";
import { default as delete_45pwdOJdBNtUK5lMeta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/delete-pwd.vue?macro=true";
import { default as downloadruYXTD7tw1Meta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/download.vue?macro=true";
import { default as emailcArzVRMzZ3Meta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/email.vue?macro=true";
import { default as indexU9kBvUzcCbMeta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/index.vue?macro=true";
import { default as myemailWPxDlG7H01Meta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/myemail.vue?macro=true";
import { default as passwordGXnBKbr8PMMeta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/password.vue?macro=true";
import { default as phoneW62BpDRAK8Meta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/phone.vue?macro=true";
import { default as privacy_45policyiHgfXelTlrMeta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/privacy-policy.vue?macro=true";
import { default as privacyZbQpNlks4QMeta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/privacy.vue?macro=true";
import { default as resetPasswordqRWzerxBsuMeta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/resetPassword.vue?macro=true";
import { default as terms_45of_45serviceB9BaQH9HXAMeta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/terms-of-service.vue?macro=true";
import { default as why_45leaveXQfxH26xZ0Meta } from "/usr/local/www/1000real-nuxt-pre/pages/setting/why-leave.vue?macro=true";
import { default as age_45gateykSuOOiGV0Meta } from "/usr/local/www/1000real-nuxt-pre/pages/signup/age-gate.vue?macro=true";
import { default as create_45passwords4T0uhaDw9Meta } from "/usr/local/www/1000real-nuxt-pre/pages/signup/create-password.vue?macro=true";
import { default as create_45usernameCSnPDN9dJ9Meta } from "/usr/local/www/1000real-nuxt-pre/pages/signup/create-username.vue?macro=true";
import { default as user_45pagewJbdU5LOORMeta } from "/usr/local/www/1000real-nuxt-pre/pages/user-page.vue?macro=true";
import { default as who_45rated_45merDmu44U1CLMeta } from "/usr/local/www/1000real-nuxt-pre/pages/who-rated-me.vue?macro=true";
export default [
  {
    name: about_45ustxeYczLJftMeta?.name ?? "about-us",
    path: about_45ustxeYczLJftMeta?.path ?? "/about-us",
    meta: about_45ustxeYczLJftMeta || {},
    alias: about_45ustxeYczLJftMeta?.alias || [],
    redirect: about_45ustxeYczLJftMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: edit_45biofGg3qd43x2Meta?.name ?? "account-edit-bio",
    path: edit_45biofGg3qd43x2Meta?.path ?? "/account/edit-bio",
    meta: edit_45biofGg3qd43x2Meta || {},
    alias: edit_45biofGg3qd43x2Meta?.alias || [],
    redirect: edit_45biofGg3qd43x2Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/account/edit-bio.vue").then(m => m.default || m)
  },
  {
    name: edit_45namec1nyjI1EHKMeta?.name ?? "account-edit-name",
    path: edit_45namec1nyjI1EHKMeta?.path ?? "/account/edit-name",
    meta: edit_45namec1nyjI1EHKMeta || {},
    alias: edit_45namec1nyjI1EHKMeta?.alias || [],
    redirect: edit_45namec1nyjI1EHKMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/account/edit-name.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileJwaNPsYmQmMeta?.name ?? "account-edit-profile",
    path: edit_45profileJwaNPsYmQmMeta?.path ?? "/account/edit-profile",
    meta: edit_45profileJwaNPsYmQmMeta || {},
    alias: edit_45profileJwaNPsYmQmMeta?.alias || [],
    redirect: edit_45profileJwaNPsYmQmMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/account/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: edit_45usernameKnl7e10ak2Meta?.name ?? "account-edit-username",
    path: edit_45usernameKnl7e10ak2Meta?.path ?? "/account/edit-username",
    meta: edit_45usernameKnl7e10ak2Meta || {},
    alias: edit_45usernameKnl7e10ak2Meta?.alias || [],
    redirect: edit_45usernameKnl7e10ak2Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/account/edit-username.vue").then(m => m.default || m)
  },
  {
    name: animationDhSGehUlH4Meta?.name ?? "animation",
    path: animationDhSGehUlH4Meta?.path ?? "/animation",
    meta: animationDhSGehUlH4Meta || {},
    alias: animationDhSGehUlH4Meta?.alias || [],
    redirect: animationDhSGehUlH4Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/animation.vue").then(m => m.default || m)
  },
  {
    name: creat_45genderu7EaY1AQuUMeta?.name ?? "auth-creat-gender",
    path: creat_45genderu7EaY1AQuUMeta?.path ?? "/auth/creat-gender",
    meta: creat_45genderu7EaY1AQuUMeta || {},
    alias: creat_45genderu7EaY1AQuUMeta?.alias || [],
    redirect: creat_45genderu7EaY1AQuUMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/creat-gender.vue").then(m => m.default || m)
  },
  {
    name: create_45emailzF7Yq2LGxGMeta?.name ?? "auth-create-email",
    path: create_45emailzF7Yq2LGxGMeta?.path ?? "/auth/create-email",
    meta: create_45emailzF7Yq2LGxGMeta || {},
    alias: create_45emailzF7Yq2LGxGMeta?.alias || [],
    redirect: create_45emailzF7Yq2LGxGMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/create-email.vue").then(m => m.default || m)
  },
  {
    name: create_45infokImBK35jX7Meta?.name ?? "auth-create-info",
    path: create_45infokImBK35jX7Meta?.path ?? "/auth/create-info",
    meta: create_45infokImBK35jX7Meta || {},
    alias: create_45infokImBK35jX7Meta?.alias || [],
    redirect: create_45infokImBK35jX7Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/create-info.vue").then(m => m.default || m)
  },
  {
    name: create_45phoneTMRuKnyWNUMeta?.name ?? "auth-create-phone",
    path: create_45phoneTMRuKnyWNUMeta?.path ?? "/auth/create-phone",
    meta: create_45phoneTMRuKnyWNUMeta || {},
    alias: create_45phoneTMRuKnyWNUMeta?.alias || [],
    redirect: create_45phoneTMRuKnyWNUMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/create-phone.vue").then(m => m.default || m)
  },
  {
    name: create_45photoCIc3F9FFS6Meta?.name ?? "auth-create-photo",
    path: create_45photoCIc3F9FFS6Meta?.path ?? "/auth/create-photo",
    meta: create_45photoCIc3F9FFS6Meta || {},
    alias: create_45photoCIc3F9FFS6Meta?.alias || [],
    redirect: create_45photoCIc3F9FFS6Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/create-photo.vue").then(m => m.default || m)
  },
  {
    name: create_45userAndBirthKpMkBlY48iMeta?.name ?? "auth-create-userAndBirth",
    path: create_45userAndBirthKpMkBlY48iMeta?.path ?? "/auth/create-userAndBirth",
    meta: create_45userAndBirthKpMkBlY48iMeta || {},
    alias: create_45userAndBirthKpMkBlY48iMeta?.alias || [],
    redirect: create_45userAndBirthKpMkBlY48iMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/create-userAndBirth.vue").then(m => m.default || m)
  },
  {
    name: create_45verifyxqr3XLl7liMeta?.name ?? "auth-create-verify",
    path: create_45verifyxqr3XLl7liMeta?.path ?? "/auth/create-verify",
    meta: create_45verifyxqr3XLl7liMeta || {},
    alias: create_45verifyxqr3XLl7liMeta?.alias || [],
    redirect: create_45verifyxqr3XLl7liMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/create-verify.vue").then(m => m.default || m)
  },
  {
    name: digit_45code0UiXyEg24XMeta?.name ?? "auth-digit-code",
    path: digit_45code0UiXyEg24XMeta?.path ?? "/auth/digit-code",
    meta: digit_45code0UiXyEg24XMeta || {},
    alias: digit_45code0UiXyEg24XMeta?.alias || [],
    redirect: digit_45code0UiXyEg24XMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/digit-code.vue").then(m => m.default || m)
  },
  {
    name: forgotPasswordT6JNI1AjC3Meta?.name ?? "auth-forgotPassword",
    path: forgotPasswordT6JNI1AjC3Meta?.path ?? "/auth/forgotPassword",
    meta: forgotPasswordT6JNI1AjC3Meta || {},
    alias: forgotPasswordT6JNI1AjC3Meta?.alias || [],
    redirect: forgotPasswordT6JNI1AjC3Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/forgotPassword.vue").then(m => m.default || m)
  },
  {
    name: indexpw1hSDKUDxMeta?.name ?? "auth",
    path: indexpw1hSDKUDxMeta?.path ?? "/auth",
    meta: indexpw1hSDKUDxMeta || {},
    alias: indexpw1hSDKUDxMeta?.alias || [],
    redirect: indexpw1hSDKUDxMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: phone_45or_45emailsEVn84zpAqMeta?.name ?? "auth-phone-or-email",
    path: phone_45or_45emailsEVn84zpAqMeta?.path ?? "/auth/phone-or-email",
    meta: phone_45or_45emailsEVn84zpAqMeta || {},
    alias: phone_45or_45emailsEVn84zpAqMeta?.alias || [],
    redirect: phone_45or_45emailsEVn84zpAqMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/auth/phone-or-email.vue").then(m => m.default || m)
  },
  {
    name: chatXLakwlrUaCMeta?.name ?? "chat",
    path: chatXLakwlrUaCMeta?.path ?? "/chat",
    meta: chatXLakwlrUaCMeta || {},
    alias: chatXLakwlrUaCMeta?.alias || [],
    redirect: chatXLakwlrUaCMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: chatroom_45listP5cnqgLa2NMeta?.name ?? "chatroom-list",
    path: chatroom_45listP5cnqgLa2NMeta?.path ?? "/chatroom-list",
    meta: chatroom_45listP5cnqgLa2NMeta || {},
    alias: chatroom_45listP5cnqgLa2NMeta?.alias || [],
    redirect: chatroom_45listP5cnqgLa2NMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/chatroom-list.vue").then(m => m.default || m)
  },
  {
    name: chatroomoCxNx63pEyMeta?.name ?? "chatroom",
    path: chatroomoCxNx63pEyMeta?.path ?? "/chatroom",
    meta: chatroomoCxNx63pEyMeta || {},
    alias: chatroomoCxNx63pEyMeta?.alias || [],
    redirect: chatroomoCxNx63pEyMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/chatroom.vue").then(m => m.default || m)
  },
  {
    name: community_45guidelinestkdzoebPsSMeta?.name ?? "community-guidelines",
    path: community_45guidelinestkdzoebPsSMeta?.path ?? "/community-guidelines",
    meta: community_45guidelinestkdzoebPsSMeta || {},
    alias: community_45guidelinestkdzoebPsSMeta?.alias || [],
    redirect: community_45guidelinestkdzoebPsSMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/community-guidelines.vue").then(m => m.default || m)
  },
  {
    name: communitydSvfwGeIegMeta?.name ?? "community",
    path: communitydSvfwGeIegMeta?.path ?? "/community",
    meta: communitydSvfwGeIegMeta || {},
    alias: communitydSvfwGeIegMeta?.alias || [],
    redirect: communitydSvfwGeIegMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/community.vue").then(m => m.default || m)
  },
  {
    name: discoverbH7nGgrWvvMeta?.name ?? "discover",
    path: discoverbH7nGgrWvvMeta?.path ?? "/discover",
    meta: discoverbH7nGgrWvvMeta || {},
    alias: discoverbH7nGgrWvvMeta?.alias || [],
    redirect: discoverbH7nGgrWvvMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/discover.vue").then(m => m.default || m)
  },
  {
    name: editBankInfo7DVN8g3EnJMeta?.name ?? "editBankInfo",
    path: editBankInfo7DVN8g3EnJMeta?.path ?? "/editBankInfo",
    meta: editBankInfo7DVN8g3EnJMeta || {},
    alias: editBankInfo7DVN8g3EnJMeta?.alias || [],
    redirect: editBankInfo7DVN8g3EnJMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/editBankInfo.vue").then(m => m.default || m)
  },
  {
    name: feedbackR8nHCcDSdEMeta?.name ?? "feedback",
    path: feedbackR8nHCcDSdEMeta?.path ?? "/feedback",
    meta: feedbackR8nHCcDSdEMeta || {},
    alias: feedbackR8nHCcDSdEMeta?.alias || [],
    redirect: feedbackR8nHCcDSdEMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/feedback.vue").then(m => m.default || m)
  },
  {
    name: follow_45list1H7d99OS8mMeta?.name ?? "follow-list",
    path: follow_45list1H7d99OS8mMeta?.path ?? "/follow-list",
    meta: follow_45list1H7d99OS8mMeta || {},
    alias: follow_45list1H7d99OS8mMeta?.alias || [],
    redirect: follow_45list1H7d99OS8mMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/follow-list.vue").then(m => m.default || m)
  },
  {
    name: foryoudVw71Uo2sDMeta?.name ?? "foryou",
    path: foryoudVw71Uo2sDMeta?.path ?? "/foryou",
    meta: foryoudVw71Uo2sDMeta || {},
    alias: foryoudVw71Uo2sDMeta?.alias || [],
    redirect: foryoudVw71Uo2sDMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/foryou.vue").then(m => m.default || m)
  },
  {
    name: add_45friendcMtQesKCnvMeta?.name ?? "friend-add-friend",
    path: add_45friendcMtQesKCnvMeta?.path ?? "/friend/add-friend",
    meta: add_45friendcMtQesKCnvMeta || {},
    alias: add_45friendcMtQesKCnvMeta?.alias || [],
    redirect: add_45friendcMtQesKCnvMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/friend/add-friend.vue").then(m => m.default || m)
  },
  {
    name: indexdlzrm2lOILMeta?.name ?? "friend",
    path: indexdlzrm2lOILMeta?.path ?? "/friend",
    meta: indexdlzrm2lOILMeta || {},
    alias: indexdlzrm2lOILMeta?.alias || [],
    redirect: indexdlzrm2lOILMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/friend/index.vue").then(m => m.default || m)
  },
  {
    name: inboxQtsIOZQv2sMeta?.name ?? "inbox",
    path: inboxQtsIOZQv2sMeta?.path ?? "/inbox",
    meta: inboxQtsIOZQv2sMeta || {},
    alias: inboxQtsIOZQv2sMeta?.alias || [],
    redirect: inboxQtsIOZQv2sMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/inbox.vue").then(m => m.default || m)
  },
  {
    name: indextXwaCr5piNMeta?.name ?? "index",
    path: indextXwaCr5piNMeta?.path ?? "/",
    meta: indextXwaCr5piNMeta || {},
    alias: indextXwaCr5piNMeta?.alias || [],
    redirect: indextXwaCr5piNMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invite_45homel81j7WEFByMeta?.name ?? "invite-home",
    path: invite_45homel81j7WEFByMeta?.path ?? "/invite-home",
    meta: invite_45homel81j7WEFByMeta || {},
    alias: invite_45homel81j7WEFByMeta?.alias || [],
    redirect: invite_45homel81j7WEFByMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/invite-home.vue").then(m => m.default || m)
  },
  {
    name: invitepF6W5AGcYaMeta?.name ?? "invite",
    path: invitepF6W5AGcYaMeta?.path ?? "/invite",
    meta: invitepF6W5AGcYaMeta || {},
    alias: invitepF6W5AGcYaMeta?.alias || [],
    redirect: invitepF6W5AGcYaMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/invite.vue").then(m => m.default || m)
  },
  {
    name: login5suwKbkZy5Meta?.name ?? "login",
    path: login5suwKbkZy5Meta?.path ?? "/login",
    meta: login5suwKbkZy5Meta || {},
    alias: login5suwKbkZy5Meta?.alias || [],
    redirect: login5suwKbkZy5Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/login.vue").then(m => m.default || m)
  },
  {
    name: myScoreoYtKMg4mJOMeta?.name ?? "myScore",
    path: myScoreoYtKMg4mJOMeta?.path ?? "/myScore",
    meta: myScoreoYtKMg4mJOMeta || {},
    alias: myScoreoYtKMg4mJOMeta?.alias || [],
    redirect: myScoreoYtKMg4mJOMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/myScore.vue").then(m => m.default || m)
  },
  {
    name: notLogInShareGSa2nHFSrNMeta?.name ?? "notLogInShare",
    path: notLogInShareGSa2nHFSrNMeta?.path ?? "/notLogInShare",
    meta: notLogInShareGSa2nHFSrNMeta || {},
    alias: notLogInShareGSa2nHFSrNMeta?.alias || [],
    redirect: notLogInShareGSa2nHFSrNMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/notLogInShare.vue").then(m => m.default || m)
  },
  {
    name: post_45previewWdwcYDVB54Meta?.name ?? "post-preview",
    path: post_45previewWdwcYDVB54Meta?.path ?? "/post-preview",
    meta: post_45previewWdwcYDVB54Meta || {},
    alias: post_45previewWdwcYDVB54Meta?.alias || [],
    redirect: post_45previewWdwcYDVB54Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/post-preview.vue").then(m => m.default || m)
  },
  {
    name: post84Ws3ZzI2hMeta?.name ?? "post",
    path: post84Ws3ZzI2hMeta?.path ?? "/post",
    meta: post84Ws3ZzI2hMeta || {},
    alias: post84Ws3ZzI2hMeta?.alias || [],
    redirect: post84Ws3ZzI2hMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/post.vue").then(m => m.default || m)
  },
  {
    name: profileRqEoFjlEspMeta?.name ?? "profile",
    path: profileRqEoFjlEspMeta?.path ?? "/profile",
    meta: profileRqEoFjlEspMeta || {},
    alias: profileRqEoFjlEspMeta?.alias || [],
    redirect: profileRqEoFjlEspMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: rankingsN9td36kjFZMeta?.name ?? "rankings",
    path: rankingsN9td36kjFZMeta?.path ?? "/rankings",
    meta: rankingsN9td36kjFZMeta || {},
    alias: rankingsN9td36kjFZMeta?.alias || [],
    redirect: rankingsN9td36kjFZMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/rankings.vue").then(m => m.default || m)
  },
  {
    name: registerobOAatp0gHMeta?.name ?? "register",
    path: registerobOAatp0gHMeta?.path ?? "/register",
    meta: registerobOAatp0gHMeta || {},
    alias: registerobOAatp0gHMeta?.alias || [],
    redirect: registerobOAatp0gHMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resetPwdme7ZY9CCynMeta?.name ?? "resetPwd",
    path: resetPwdme7ZY9CCynMeta?.path ?? "/resetPwd",
    meta: resetPwdme7ZY9CCynMeta || {},
    alias: resetPwdme7ZY9CCynMeta?.alias || [],
    redirect: resetPwdme7ZY9CCynMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/resetPwd.vue").then(m => m.default || m)
  },
  {
    name: searchaUelDVM4GkMeta?.name ?? "search",
    path: searchaUelDVM4GkMeta?.path ?? "/search",
    meta: searchaUelDVM4GkMeta || {},
    alias: searchaUelDVM4GkMeta?.alias || [],
    redirect: searchaUelDVM4GkMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchTidAOPrFqhW7KMeta?.name ?? "searchTid",
    path: searchTidAOPrFqhW7KMeta?.path ?? "/searchTid",
    meta: searchTidAOPrFqhW7KMeta || {},
    alias: searchTidAOPrFqhW7KMeta?.alias || [],
    redirect: searchTidAOPrFqhW7KMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/searchTid.vue").then(m => m.default || m)
  },
  {
    name: accountt0aeXSzlyKMeta?.name ?? "setting-account",
    path: accountt0aeXSzlyKMeta?.path ?? "/setting/account",
    meta: accountt0aeXSzlyKMeta || {},
    alias: accountt0aeXSzlyKMeta?.alias || [],
    redirect: accountt0aeXSzlyKMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/account.vue").then(m => m.default || m)
  },
  {
    name: contact_45us58hiPpkIp2Meta?.name ?? "setting-contact-us",
    path: contact_45us58hiPpkIp2Meta?.path ?? "/setting/contact-us",
    meta: contact_45us58hiPpkIp2Meta || {},
    alias: contact_45us58hiPpkIp2Meta?.alias || [],
    redirect: contact_45us58hiPpkIp2Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/contact-us.vue").then(m => m.default || m)
  },
  {
    name: copyright_45policyJlY4vEG2dlMeta?.name ?? "setting-copyright-policy",
    path: copyright_45policyJlY4vEG2dlMeta?.path ?? "/setting/copyright-policy",
    meta: copyright_45policyJlY4vEG2dlMeta || {},
    alias: copyright_45policyJlY4vEG2dlMeta?.alias || [],
    redirect: copyright_45policyJlY4vEG2dlMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/copyright-policy.vue").then(m => m.default || m)
  },
  {
    name: delete_45account4WfrF0v48oMeta?.name ?? "setting-delete-account",
    path: delete_45account4WfrF0v48oMeta?.path ?? "/setting/delete-account",
    meta: delete_45account4WfrF0v48oMeta || {},
    alias: delete_45account4WfrF0v48oMeta?.alias || [],
    redirect: delete_45account4WfrF0v48oMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45noteezgz5qXHR5Meta?.name ?? "setting-delete-note",
    path: delete_45noteezgz5qXHR5Meta?.path ?? "/setting/delete-note",
    meta: delete_45noteezgz5qXHR5Meta || {},
    alias: delete_45noteezgz5qXHR5Meta?.alias || [],
    redirect: delete_45noteezgz5qXHR5Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/delete-note.vue").then(m => m.default || m)
  },
  {
    name: delete_45pwdOJdBNtUK5lMeta?.name ?? "setting-delete-pwd",
    path: delete_45pwdOJdBNtUK5lMeta?.path ?? "/setting/delete-pwd",
    meta: delete_45pwdOJdBNtUK5lMeta || {},
    alias: delete_45pwdOJdBNtUK5lMeta?.alias || [],
    redirect: delete_45pwdOJdBNtUK5lMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/delete-pwd.vue").then(m => m.default || m)
  },
  {
    name: downloadruYXTD7tw1Meta?.name ?? "setting-download",
    path: downloadruYXTD7tw1Meta?.path ?? "/setting/download",
    meta: downloadruYXTD7tw1Meta || {},
    alias: downloadruYXTD7tw1Meta?.alias || [],
    redirect: downloadruYXTD7tw1Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/download.vue").then(m => m.default || m)
  },
  {
    name: emailcArzVRMzZ3Meta?.name ?? "setting-email",
    path: emailcArzVRMzZ3Meta?.path ?? "/setting/email",
    meta: emailcArzVRMzZ3Meta || {},
    alias: emailcArzVRMzZ3Meta?.alias || [],
    redirect: emailcArzVRMzZ3Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/email.vue").then(m => m.default || m)
  },
  {
    name: indexU9kBvUzcCbMeta?.name ?? "setting",
    path: indexU9kBvUzcCbMeta?.path ?? "/setting",
    meta: indexU9kBvUzcCbMeta || {},
    alias: indexU9kBvUzcCbMeta?.alias || [],
    redirect: indexU9kBvUzcCbMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: myemailWPxDlG7H01Meta?.name ?? "setting-myemail",
    path: myemailWPxDlG7H01Meta?.path ?? "/setting/myemail",
    meta: myemailWPxDlG7H01Meta || {},
    alias: myemailWPxDlG7H01Meta?.alias || [],
    redirect: myemailWPxDlG7H01Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/myemail.vue").then(m => m.default || m)
  },
  {
    name: passwordGXnBKbr8PMMeta?.name ?? "setting-password",
    path: passwordGXnBKbr8PMMeta?.path ?? "/setting/password",
    meta: passwordGXnBKbr8PMMeta || {},
    alias: passwordGXnBKbr8PMMeta?.alias || [],
    redirect: passwordGXnBKbr8PMMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/password.vue").then(m => m.default || m)
  },
  {
    name: phoneW62BpDRAK8Meta?.name ?? "setting-phone",
    path: phoneW62BpDRAK8Meta?.path ?? "/setting/phone",
    meta: phoneW62BpDRAK8Meta || {},
    alias: phoneW62BpDRAK8Meta?.alias || [],
    redirect: phoneW62BpDRAK8Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/phone.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyiHgfXelTlrMeta?.name ?? "setting-privacy-policy",
    path: privacy_45policyiHgfXelTlrMeta?.path ?? "/setting/privacy-policy",
    meta: privacy_45policyiHgfXelTlrMeta || {},
    alias: privacy_45policyiHgfXelTlrMeta?.alias || [],
    redirect: privacy_45policyiHgfXelTlrMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacyZbQpNlks4QMeta?.name ?? "setting-privacy",
    path: privacyZbQpNlks4QMeta?.path ?? "/setting/privacy",
    meta: privacyZbQpNlks4QMeta || {},
    alias: privacyZbQpNlks4QMeta?.alias || [],
    redirect: privacyZbQpNlks4QMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/privacy.vue").then(m => m.default || m)
  },
  {
    name: resetPasswordqRWzerxBsuMeta?.name ?? "setting-resetPassword",
    path: resetPasswordqRWzerxBsuMeta?.path ?? "/setting/resetPassword",
    meta: resetPasswordqRWzerxBsuMeta || {},
    alias: resetPasswordqRWzerxBsuMeta?.alias || [],
    redirect: resetPasswordqRWzerxBsuMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/resetPassword.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45serviceB9BaQH9HXAMeta?.name ?? "setting-terms-of-service",
    path: terms_45of_45serviceB9BaQH9HXAMeta?.path ?? "/setting/terms-of-service",
    meta: terms_45of_45serviceB9BaQH9HXAMeta || {},
    alias: terms_45of_45serviceB9BaQH9HXAMeta?.alias || [],
    redirect: terms_45of_45serviceB9BaQH9HXAMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: why_45leaveXQfxH26xZ0Meta?.name ?? "setting-why-leave",
    path: why_45leaveXQfxH26xZ0Meta?.path ?? "/setting/why-leave",
    meta: why_45leaveXQfxH26xZ0Meta || {},
    alias: why_45leaveXQfxH26xZ0Meta?.alias || [],
    redirect: why_45leaveXQfxH26xZ0Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/setting/why-leave.vue").then(m => m.default || m)
  },
  {
    name: age_45gateykSuOOiGV0Meta?.name ?? "signup-age-gate",
    path: age_45gateykSuOOiGV0Meta?.path ?? "/signup/age-gate",
    meta: age_45gateykSuOOiGV0Meta || {},
    alias: age_45gateykSuOOiGV0Meta?.alias || [],
    redirect: age_45gateykSuOOiGV0Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/signup/age-gate.vue").then(m => m.default || m)
  },
  {
    name: create_45passwords4T0uhaDw9Meta?.name ?? "signup-create-password",
    path: create_45passwords4T0uhaDw9Meta?.path ?? "/signup/create-password",
    meta: create_45passwords4T0uhaDw9Meta || {},
    alias: create_45passwords4T0uhaDw9Meta?.alias || [],
    redirect: create_45passwords4T0uhaDw9Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/signup/create-password.vue").then(m => m.default || m)
  },
  {
    name: create_45usernameCSnPDN9dJ9Meta?.name ?? "signup-create-username",
    path: create_45usernameCSnPDN9dJ9Meta?.path ?? "/signup/create-username",
    meta: create_45usernameCSnPDN9dJ9Meta || {},
    alias: create_45usernameCSnPDN9dJ9Meta?.alias || [],
    redirect: create_45usernameCSnPDN9dJ9Meta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/signup/create-username.vue").then(m => m.default || m)
  },
  {
    name: user_45pagewJbdU5LOORMeta?.name ?? "user-page",
    path: user_45pagewJbdU5LOORMeta?.path ?? "/user-page",
    meta: user_45pagewJbdU5LOORMeta || {},
    alias: user_45pagewJbdU5LOORMeta?.alias || [],
    redirect: user_45pagewJbdU5LOORMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/user-page.vue").then(m => m.default || m)
  },
  {
    name: who_45rated_45merDmu44U1CLMeta?.name ?? "who-rated-me",
    path: who_45rated_45merDmu44U1CLMeta?.path ?? "/who-rated-me",
    meta: who_45rated_45merDmu44U1CLMeta || {},
    alias: who_45rated_45merDmu44U1CLMeta?.alias || [],
    redirect: who_45rated_45merDmu44U1CLMeta?.redirect,
    component: () => import("/usr/local/www/1000real-nuxt-pre/pages/who-rated-me.vue").then(m => m.default || m)
  }
]