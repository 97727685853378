import dayjs from "dayjs";
import endsWith from "lodash/endsWith";
import relativeTime from "dayjs/plugin/relativeTime";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { showToast } from "vant";
import { getHomeList } from "/utils/post";
import { getMyInfoSync } from "../utils/api";
import useUser from "../store/user";
import { storeToRefs } from "pinia";
import { checkCanUploadSync } from "@/utils/api";

//判断是否能够传照片
export const rentopost = () => {
    const ispost = useCookie("ispost");
    const src = useCookie("src");
    checkCanUploadSync()
        .then((res) => {
            ispost.value = false;
        })
        .catch((err) => {
            if (err.code === 1004) {
                src.value = err?.data?.attachSrc;
                ispost.value = true;
            }
        })
        .finally(() => {
            navigateTo("/post");
        });
};
// 将时间戳转为文字
export const timeToText = (time) => {
    time = time * 1000;
    dayjs.extend(relativeTime);
    // 1天内显示 xx ago，其余显示mm-dd
    let text;
    if (dayjs().subtract(1, "day").isAfter(dayjs(time))) {
        text = dayjs(time).format("MM-DD");
    } else {
        text = dayjs(time).fromNow();
    }
    return text;
};

// 检查一个生日是否已成年
export const isAdult = (date) => {
    return !dayjs().subtract(18, "year").isBefore(dayjs(date));
};

// 获取指纹
export const getFingerprint = async () => {
    const fpPromise = FingerprintJS.load({
        monitoring: false,
    });
    const fp = await fpPromise;
    const result = await fp.get();
    return result?.visitorId;
};

// 添加在oss视频链接之后，可以变成预览图片
export const ossVideoCover = "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto";

// 统一的日期格式，timeStamp为时间戳-秒
export const formatDateTime = (timeStamp) => {
    return dayjs(timeStamp * 1000).format("MMM DD, YYYY HH:mm");
};

export const formatDate = (timeStamp) => {
    return dayjs(timeStamp * 1000).format("MM/DD/YYYY");
};

// 时间格式 - 星期, 月 日, 年
export const formatWeekDate = (timeStamp) => {
    return dayjs(timeStamp * 1000).format("ddd, MMM DD, YYYY");
};

export const formatWeekTime = (timeStamp) => {
    return dayjs(timeStamp * 1000).format("ddd, MMM DD, YYYY HH:mm");
};

// 监测登录态，如果未登录， 就不执行后续内容，而是跳转到登录页
export const checkAuth = () => {
    const userinfo = useCookie("userinfo");
    let auth = true;
    if (!userinfo.value) {
        auth = false;
        // navigateTo("signup");
    }
    return auth;
};

// 退出
export const logout = async () => {
    clearAllCookies();
    clearNuxtData();
    clearStore();
    // localStorage.clear();
    localStorage.removeItem("MyActive");
    const avatarState = useState("avatarState", () => "");
    // loginSuccess({
    //     // userinfo: JSON.parse(localStorage.getItem("VisitorInformation")),
    //     // token: localStorage.getItem("VisitorToken"),
    //     isshowToast: false,
    //     refreshRate: true,
    //     to: "/profile",
    //     isgetmy: false,
    // });
    window.location.href = '/login'
    // await navigateTo("/foryou");
};

// 清空store
const clearStore = (bool) => {
    const userStore = useUser();
    const { myUserInfo, token } = storeToRefs(userStore);
    userStore.$patch((state) => {
        state.myUserInfo = {};
        state.token = "";
        state.homeList = [];
    });
};

// 客户端
// const clearAllCookies = () => {
// 	// 获取当前所有的 Cookie
// 	const cookies = document.cookie.split(';');

// 	// 遍历所有的 Cookie，将它们设置为过去的时间来清空
// 	for (let i = 0; i < cookies.length; i++) {
// 		const cookie = cookies[i];
// 		const eqPos = cookie.indexOf('=');
// 		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
// 		document.cookie =
// 			`${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${window.location.hostname}`;
// 	}
// }
const clearAllCookies = () => {
    // 获取当前所有的 Cookie
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        if (cookies[i].indexOf('tid=') == -1 && cookies[i].indexOf('isBwfilter=') == -1 && cookies[i].indexOf('onlyImgSite=') == -1 && cookies[i].indexOf('siteStyleType=') == -1 && cookies[i].indexOf('gaid=') == -1 && cookies[i].indexOf('allowSelectFile=') == -1 && cookies[i].indexOf('hasMultiType=') == -1 && cookies[i].indexOf('inviteCode=') == -1 && cookies[i].indexOf('isAfricaSite=') == -1 && cookies[i].indexOf('googtrans=') == -1) { //不清除tid
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    }
};

// 校验邮箱
export const isMail = (value) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
    );
};

// 生成指定长度的随机字符串
export const getRandomStr = (args) => {
    const len = args?.len ? args.len : 8;
    const type = args?.type ? args.type : "str";
    let _charStr = "abacdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    if (type == "int") {
        _charStr = "0123456789";
    }
    let _str = "";
    //循环生成字符串
    for (var i = 0; i < len; i++) {
        _str += _charStr.charAt(Math.floor(Math.random() * _charStr.length));
    }
    return _str;
};

// 保存用户到本地
export const saveUserToLoacl = (res, saveToken = true) => {
    // 存pinia和storage
    if (saveToken) {
        localStorage.setItem("token", res?.token);
    }
    //localStorage.setItem('userinfo', JSON.stringify(res.data));
    //userStore().userinfo = res.data;
};

// 检查资源是不是视频
export const isVideo = (file) => {
    if (
        endsWith(file, ".mp4") ||
        endsWith(file, ".avi") ||
        endsWith(file, ".wmv") ||
        endsWith(file, ".mpg") ||
        endsWith(file, ".mpeg")
    ) {
        return true;
    } else {
        return false;
    }
};

// 登录成功之后
export const loginSuccess = async (args) => {
    const currentUserType = useCookie("currentUserType");
    const userinfo = useCookie("userinfo");
    if (args.isshowToast !== false) {
        showToast({
            message: `${args?.act == "signup" ? "Sign Up" : "Login"} Success`,
            position: "top",
        });
    }
    // 将用户信息存入cookie
    await setMyInfo({
        userinfo: args.userinfo,
        token: args.token,
    });
    if (!args.isgetmy) {
        await getMyInfoSync().then((res) => {
            const userStore = useUser();
            const { myUserInfo, token } = storeToRefs(userStore);
            setMyInfo({
                userinfo: res.data,
                token: res.token,
            });
            userStore.$patch((state) => {
                state.myUserInfo = res?.data;
                state.token = res?.token;
            });
            currentUserType.value = res?.data && parseInt(res?.data.hasSelfie) == 1 ? 1 : 0;
        });
    } else {
        currentUserType.value = userinfo.value && parseInt(userinfo.value.hasSelfie) == 1 ? 1 : 0;
    }
    // 获取个人信息

    const type = "foryou";
    const list = useState(`${type}Home`);
    if (args.refreshRate == true) {
        // 刷新首页视频
        list.value = [];
        // // 查第1页 - 只有2个视频
        // await getHomeList({
        //     type,
        //     page: 1,
        // });
    }

    // 查第2页 - 有5个视频
    // getHomeList({
    //     type,
    //     page: 2,
    // });
    const verifyEmailDialogAction = useState("verifyEmailDialogAction")
    if (args.notto) {
        verifyEmailDialogAction.value = new Date().getTime()
        return;
    }
    // 设置自动跳转chatroom聊天室
    // let chatrooms = localStorage.getItem("chatrooms") == null ? [] : JSON.parse(localStorage.getItem("chatrooms"));
    // let jumpFun = async () => {
    //     let userinfo = useCookie("userinfo");
    //     if (userinfo?.value && userinfo.value?.top < 30 && parseInt(userinfo.value?.userScoreCnt) >= 5) {
    //         let roomId = ''
    //         for (let i = 0; i < chatrooms.length; i++) {
    //             let bool = false
    //             bool = userinfo.value?.top > chatrooms[i].minRanking && userinfo.value?.top <= chatrooms[i].maxRanking
    //             if (parseInt(userinfo.value?.top) == 0) {
    //                 bool = userinfo.value?.top >= chatrooms[i].minRanking && userinfo.value?.top <= chatrooms[i].maxRanking
    //             }
    //             if (bool) {
    //                 roomId = chatrooms[i].roomId
    //                 break
    //             }
    //         }
    //         roomId ? await navigateTo("/chatroom?back=foryou&roomId=" + roomId) : await navigateTo(args?.to || "")
    //     } else {
    //         await navigateTo(args?.to || "");
    //     }
    // };
    // if (chatrooms.length > 0) {
    //     jumpFun()
    // } else {
    //     getChatroomConfig().then((res) => {
    //         if (res && res.data && res.data.length > 0) {
    //             localStorage.setItem("chatrooms", JSON.stringify(res.data))
    //             chatrooms = localStorage.getItem("chatrooms") == null ? [] : JSON.parse(localStorage.getItem("chatrooms"));
    //         }
    //         jumpFun()
    //     })
    // }
    await navigateTo(args?.to || "");
    // 跳转
    verifyEmailDialogAction.value = new Date().getTime()

    //清除上一个用户的缓存
    const myPostList = useState("myPost");
    const likePostList = useState('likePost');
    myPostList.value = []
    likePostList.value = []
};

export const setMyInfo = (args) => {
    const userinfoCookie = useCookie("userinfo", {
        path: "/",
        maxAge: 365 * 24 * 60 * 60,
    });
    // setTimeout(() => {
    if (args?.userinfo) {
        // setTimeout(() => {
            userinfoCookie.value = args?.userinfo;
        // }, 300);
    }
    // }, 300);
    const tokenCookie = useCookie("token", {
        path: "/",
        maxAge: 365 * 24 * 60 * 60,
    });
    if (args?.token) {
        tokenCookie.value = args?.token;
    }
};

// 获取视频缩略图 - 首帧 - 在浏览器执行
export const getVideoFirst = (file) => {
    return new Promise((resolve, reject) => {
        const siteTipData = useState('siteTipData')
        try {
            const size = file.size / 1000000;
            if (size > 100) {
                reject()
                siteTipData.value = Object.assign(siteTipData.value, {show: true, type: 0, message: 'Please upload a smaller-sized video.', closeTime: 4000})
                return false;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                let video = document.createElement("video")
                video.src = URL.createObjectURL(new Blob([file], { type: "video/mp4" }));
                video.currentTime = 1; // 首帧
                video.setAttribute("muted", true); //是否静音
                video.setAttribute("autoplay", true); //是否自动播放
                video.setAttribute("x5-video-player-fullscreen", true); //支持全屏播放
                video.setAttribute("webkit-playsinline", true); // 视频播放时局域播放，不脱离文档流
                video.setAttribute("playsinline", true); // 小窗播放
                video.setAttribute("x5-playsinline", true);
                video.setAttribute("x-webkit-airplay", "allow"); //支持ios的AirPlay功能
                //这里先播放在暂停，这里的目的就是让视频能够达到第一帧的地方
                video.onloadeddata = function () {
                    video.play(); //先播放视频
                };
                //在视频的onplay事件中处理图片的内容
                video.onplay = function () {
                    if (this.duration < 5) {
                        reject()
                        siteTipData.value = Object.assign(siteTipData.value, {show: true, type: 0, message: 'The video is too short. Please retake a selfie video lasting between 5 and 10 seconds.', closeTime: 4000})
                        return false;
                    }
                    setTimeout(() => {
                        const width = video.videoWidth; //图片宽度
                        const height = video.videoHeight; //图片高度
                        const canvas = document.createElement("canvas"); //创建canvas
                        const ctx = canvas.getContext("2d");
                        canvas.setAttribute("width", width);
                        canvas.setAttribute("height", height);
                        video.setAttribute("width", width);
                        video.setAttribute("height", height);
                        ctx.drawImage(video, 0, 0, video.width, video.height); //画图
                        const imgSrc = canvas.toDataURL("image/png"); //生成图片
                        resolve({
                            duration: parseInt(video.duration % 60), //得到整数的视频时长
                            imgSrc, //base64的缩略图图片路径
                            videoUrl: e.target.result,
                            width: video.videoWidth,
                            height: video.videoHeight,
                            videoName: file.name,
                        });
                        video.pause(); // 然后在暂停播放
                    }, 500);
                };
            };
        } catch (e) {
            console.log('uploadError:', e)
            siteTipData.value = Object.assign(siteTipData.value, {show: true, type: 0, message: e, closeTime: 4000})
        }
        
    });
};
