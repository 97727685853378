export default defineNuxtPlugin((nuxtApp) => {
    const { appName, appTitle, appKeywords, appDescription, engine, SERVER_PATH } =
        useRuntimeConfig().public;
    const domain = SERVER_PATH.replace("/api/", "");

    useHead({
        title: appTitle || "",
        link: [
            {
                rel: "icon",
                sizes: "192x192",
                href: `${domain}/${engine}/img/favicon.ico`,
            },
            {
                rel: "apple-touch-icon-precomposed",
                href: `${domain}/${engine}/img/applogo.png`,
            },
            {
                rel: "apple-touch-icon",
                href: `${domain}/${engine}/img/applogo.png`,
            },
            {
                rel: "manifest",
                href: `${domain}/${engine}/manifest.webmanifest`,
            },
        ],
        meta: [
            {
                name: "viewport",
                content:
                    "width=device-width, initial-scale=1 minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
            },
            {
                name: "keywords",
                content: appKeywords,
            },
            {
                name: "description",
                content: appDescription,
            },
            {
                name: "mobile-web-app-capable",
                content: "yes",
            },
            {
                name: "apple-mobile-web-app-capable",
                content: "yes",
            },
            {
                name: "apple-mobile-web-app-title",
                content: appName,
            },
            {
                property: "og:image",
                content: `${domain}/${engine}/img/applogo.png`,
            },
            {
                name: "twitter:image",
                content: `${domain}/${engine}/img/applogo.png`,
            },
        ],
    });
});
