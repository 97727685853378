<template>
    <div class="openBrowseTip" v-if="showOpenBrowseTip">
        <div class="bg" @click="close"></div>
        <div class="contentWarp">
            <div class="dec1">To better experience {{ domain }}, please open it in your mobile browser.</div>
            <div class="btn" @click="copyInviteLink">COPY THE LINK</div>
            <div class="dec2">Paste and open it in your mobile browser.</div>
        </div>
    </div>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig();
const { appName, domain } = useRuntimeConfig().public;
const showOpenBrowseTip = useState('showOpenBrowseTip');
const close = () => {
    showOpenBrowseTip.value = false
}
onMounted(()=>{
   
})
const copyInviteLink = () => {
    let copyLink = 'https://www.' + domain + '/'
    const tid =  useCookie("tid")
    if (tid.value) {
        copyLink += '?tid=' + tid.value
    }
    if (navigator.clipboard) {
        // clipboard api 复制
        navigator.clipboard.writeText(copyLink);
    } else {
        var textarea = document.createElement('textarea');
        document.body.appendChild(textarea);
        // 隐藏此输入框
        textarea.style.position = 'fixed';
        textarea.style.clip = 'rect(0 0 0 0)';
        textarea.style.top = '10px';
        // 赋值
        textarea.value = copyLink;
        // 选中
        textarea.select();
        // 复制
        document.execCommand('copy', true);
        // 移除输入框
        document.body.removeChild(textarea);
    }
    showSuccessToast({
        message: 'Link copied successfully',
        wordBreak: 'break-word',
    })
}
onUnmounted(() => {

})
</script>
<style scoped lang="scss">
.openBrowseTip {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .bg {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
    }
    .contentWarp {
        box-sizing: border-box;
        background-color: $mainftColor;
        border-radius: 0.8rem;
        margin: 0 auto;
        width: calc(100% - 4rem);
        max-width: 40rem;
        padding: 3.2rem 0;
        position: relative;
        z-index: 3;
    }
    .dec1 {
        font-size: 1.5rem;
        line-height: 2.6rem;
        text-align: center;
        margin: 0 2.4rem;
        color: $mainColor;
        word-break: break-word;
    }
    .btn {
        width: 25rem;
        height: 4rem;
        line-height: 4rem;
        text-align: center;
        background-color: $mainColor;
        color: $mainftColor;
        @extend .publicFont3;
        font-size: 1.6rem;
        margin: 2.4rem auto 0;
        border-radius: 2rem;
        &.disabled {
            opacity: .8;
        }
    }
    .dec2 {
        margin: 1.2rem 1.2rem 0;
        font-size: 1.4rem;
        color: #999999;
        line-height: 2.6rem;
        text-align: center;
    }
}
</style>