import revive_payload_client_4sVQNw7RlN from "/usr/local/www/1000real-nuxt-pre/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/local/www/1000real-nuxt-pre/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/local/www/1000real-nuxt-pre/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/local/www/1000real-nuxt-pre/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/local/www/1000real-nuxt-pre/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/local/www/1000real-nuxt-pre/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/local/www/1000real-nuxt-pre/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/local/www/1000real-nuxt-pre/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/usr/local/www/1000real-nuxt-pre/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/usr/local/www/1000real-nuxt-pre/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/usr/local/www/1000real-nuxt-pre/.nuxt/element-plus-injection.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/local/www/1000real-nuxt-pre/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_B4ptqVdIfe from "/usr/local/www/1000real-nuxt-pre/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import disableZoom_pszpvCiTL1 from "/plugins/disableZoom.js";
import vueTelInput_OfB3YnbU52 from "/usr/local/www/1000real-nuxt-pre/plugins/vueTelInput.js";
import disableZoom_18sCbAhW2V from "/usr/local/www/1000real-nuxt-pre/plugins/disableZoom.js";
import head_client_aQDgb2b175 from "/usr/local/www/1000real-nuxt-pre/plugins/head.client.js";
import head_EV6wdvsKWK from "/usr/local/www/1000real-nuxt-pre/plugins/head.js";
import i18n_sVHQBgnb3t from "/usr/local/www/1000real-nuxt-pre/plugins/i18n.js";
import sentry_client_GoGQuZo4Et from "/usr/local/www/1000real-nuxt-pre/plugins/sentry.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  chunk_reload_client_UciE0i6zes,
  ssr_plugin_B4ptqVdIfe,
  disableZoom_pszpvCiTL1,
  vueTelInput_OfB3YnbU52,
  disableZoom_18sCbAhW2V,
  head_client_aQDgb2b175,
  head_EV6wdvsKWK,
  i18n_sVHQBgnb3t,
  sentry_client_GoGQuZo4Et
]