<template>
    <div class="topCardTip" v-if="siteTipData.show">
        <div :class="{errIco: siteTipData.type == 0, successIco: siteTipData.type == 1}">
            <div class="ico">
                <IconOverallIcFailed v-if="siteTipData.type == 0" />
                <IconOverallIcSuccess v-if="siteTipData.type == 1" />
            </div>
        </div>
        <div class="tipContent">{{ siteTipData.message }}</div>
    </div>
</template>

<script setup>
const siteTipData = useState('siteTipData', ()=>{
    return {
        show: false,
        type: 1, //0失败, 1成功
        message: '',
        closeTime: 2000 //自动关闭时间
    }
});
const closeSiteTip = () => {
    siteTipData.value = {
        show: false,
        type: 1, //0失败, 1成功
        message: '',
        closeTime: 4000 //自动关闭时间
    }
}
watch(
    () => siteTipData.value,
    (n) => {
        if (siteTipData.value.show) {
            setTimeout(()=>{
                closeSiteTip()
            }, siteTipData.value.closeTime)
        }
    },
    {
        deep: true
    }
);
</script>
<style scoped lang="scss">
.topCardTip {
    position: fixed;
    left: 0;
    right: 0;
    top: 3.2rem;
    width: calc(100% - 3.2rem);
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0px 1.2rem 2.4rem 0px rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    min-height: 8rem;
    display: flex;
    align-items: center;
    z-index: 99999;
    .errIco,
    .successIco {
        height: 100%;
        flex-shrink: 0;
        width: 8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
    }
    .errIco {
        background-color: #ea5043;
    }
    .successIco {
        background-color: #43C054;
    }
    .errIco .ico,
    .successIco .ico {
        width: 2.3rem;
        height: 2.3rem;
        color: #fff;
    }
    .tipContent {
        margin: 0 0 0 8rem;
        padding: 1.2rem;
        font-size: 1.5rem;
        line-height: 2.3rem;
        font-family: OpenSans-Medium;
        color: #0f0f0f;
    }
}
</style>