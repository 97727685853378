<template>
    <div class="rateHighScoreTip" v-if="show">
        <div class="bg" @click="hideDialog"></div>
        <div class="contentWarp">
            <div class="closeBtn" @click="hideDialog">
                <IconPopoversIcCancel />
            </div>
            <div class="icon"></div>
            <div class="dec1">
                Your overall ranking is better than {{ userinforef?.top }}% <br> of people!
            </div>
            <div class="shareBtn" @click="toShare">Share My {{ onlyImgSite ? 'Photo' : 'Video' }}</div>
        </div>
    </div>
</template>

<script setup>
const runtimeConfig = useRuntimeConfig();
const showScoreDialog = ref(false)
const onlyImgSite = useCookie("onlyImgSite")
const show = ref(false)
const router = useRouter();
const timer = ref(false)
const scoreData = ref({
    postId: '',
    score: '',
    scorePercent: ''
})
onMounted(()=>{
    //showScoreDialog.value = true
    show.value = false
    if (showScoreDialog.value) {
        if (!timer.value) {
            init()
        }
    }
})
const hideDialog = () => {
     show.value = false
}
const goRegister = () => {
    hideDialog()
}
const userinfo = useCookie("userinfo");
const userinforef = useState("userinforef");
userinforef.value = userinfo.value;
const init = () => {
    if (timer.value) return
    timer.value = window.setInterval(()=>{
        let userinfo = useCookie("userinfo")
        if (userinfo.value?.visitor_token || userinfo.value == undefined) return
        postNewScore().then((res) => {
            if (res && res?.data && res.data?.postId && res.data?.score && typeof res.data.score.toFixed == 'function' && res.data?.scorePercent && typeof res.data.scorePercent.toFixed == 'function' && userinforef.value?.top) {
                scoreData.value.postId = res.data.postId
                scoreData.value.score = res.data.score.toFixed(1)
                scoreData.value.scorePercent = res.data.scorePercent.toFixed(0) < 1 ? 1 : res.data.scorePercent.toFixed(0)
                show.value = true
            }
        })
    }, 120000)
}
const toShare = () => {
    try {
        navigator.share({
            title: 'Share this ' + (onlyImgSite.value ? 'photo' : 'video') + ' with friends!',
            text: "Rate me please",
            url: `${window.location.origin}/foryou?postId=${scoreData.value.postId}&inviteCode=${(userinforef?.value?.inviteCode || '')}`
        });
    } catch (err) {
        console.log(err)
    }
    show.value = false
}
onUnmounted(() => {
    hideDialog()
})
</script>
<style scoped lang="scss">
.rateHighScoreTip {
    position: fixed;
    top: 0;
    z-index: 999999999999;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .bg {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
    }
    .contentWarp {
        box-sizing: border-box;
        background-color: #2E2E2E;
        border-radius: 0.4rem;
        margin: 0 auto;
        width: calc(100% - 3.2rem);
        max-width: 40rem;
        padding: 2.4rem 1.6rem 3.2rem;
        position: relative;
        z-index: 3;
    }
    .icon {
        width: 9.05rem;
        height: 6.03rem;
        margin: 0 auto;
        background: url('~/assets/img/pop_ic_better.png') center no-repeat;
        background-size: 100% 100%;
    }
    .closeBtn {
        width: 1rem;
        height: 1rem;
        right: 1.2rem;
        top: 1.2rem;
        position: absolute;
        color: #888888;
        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(.85);
        }
    }
    .dec1 {
        color: $mainftColor;
        font-size: 1.5rem;
        line-height: 2.6rem;
        text-align: center;
        margin-top: 2.3rem;
    }
    .shareBtn {
        width: 25rem;
        height: 4rem;
        line-height: 4rem;
        background-color: $mainftColor;
        color: $mainColor;
        border-radius: 2rem;
        text-align: center;
        @extend .publicFont3;
        margin: 2.4rem auto 0;
        font-size: 1.6rem;
    }
}
</style>