import includes from "lodash/includes";

// 这里设置navbar下标，以及首页tabbar下标
export default defineNuxtRouteMiddleware((to, from) => {
    const swiperShow = useState("swiperShow");
    if (swiperShow.value) {
        swiperShow.value = false;
        return abortNavigation();
    }
    // navbar
    const name = to.name;
    const pages = [["index", "foryou", "follow"], ["discover"], ["inbox"], ["profile"], ["chatroom"], ["community"]];
    for (let [index, item] of pages.entries()) {
        if (includes(item, name)) {
            const navIndex = useState("navIndex");
            navIndex.value = index;
            break;
        }
    }
    const userinfo = useCookie("userinfo");
    const router = useRouter();
   
    const tabIndex = useState("tabIndex", () => null);
    if (name == "index" || name == "foryou") {
        tabIndex.value = 1;
    } else if (name == "follow") {
        tabIndex.value = 0;
    }
    
    //游客或者已登录用户访问未登录的某些页面做特殊跳转
    const notLoginPaths = ['/', '/auth/create-photo', '/auth/create-email', '/login/with-signup/email', '/auth/forgotPassword', '/auth/create-verify', '/auth/create-phone', '/login', '/register', '/auth/create-info']
    const notAutoJumpPaths = ['/setting/terms-of-service', '/setting/privacy-policy', '/notLogInShare', '/profile', '/foryou', '/searchTid', '/resetPwd', '/about-us']
    if (userinfo.value?.visitor_token || userinfo.value == undefined) {
        //未登录用户访问分享链接自动跳转到未登录分享页面。
        // if (
        //     to.name == "foryou" &&
        //     to.query &&
        //     typeof to.query.postId != "undefined"
        // ) {
        //     router.push({ name: "notLogInShare", query: { postId: to.query.postId } });
        // } else {
        //     let checkPaths = notLoginPaths.concat(notAutoJumpPaths)
        //     if (!checkPaths.includes(to.path)) {
        //         router.push({ name: "profile"});
        //     }
        // }

        let checkPaths = notLoginPaths.concat(notAutoJumpPaths)
        if (!checkPaths.includes(to.path)) {
            router.push({ name: "profile"});
        }
    } else if (userinfo.value && userinfo.visitor_token == null) {
        if (notLoginPaths.includes(to.path)) {
            router.push({ name: "foryou"});
        }
    }

    //未登录注册流程埋点
    if (from.name == "foryou" && from.query && typeof from.query.postId != "undefined" && to.path == '/auth/create-email' && typeof sessionStorage != 'undefined' && typeof sessionStorage.setItem == 'function') {
        sessionStorage.setItem('registerFrom', from.path)
    }

});
