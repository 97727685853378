import validate from "/usr/local/www/1000real-nuxt-pre/node_modules/nuxt/dist/pages/runtime/validate.js";
import default_45global from "/usr/local/www/1000real-nuxt-pre/middleware/default.global.ts";
import set_45index_45global from "/usr/local/www/1000real-nuxt-pre/middleware/setIndex.global.js";
import manifest_45route_45rule from "/usr/local/www/1000real-nuxt-pre/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  default_45global,
  set_45index_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/local/www/1000real-nuxt-pre/middleware/auth.js"),
  backhome: () => import("/usr/local/www/1000real-nuxt-pre/middleware/backhome.js"),
  "login-back-home": () => import("/usr/local/www/1000real-nuxt-pre/middleware/login-back-home.js")
}