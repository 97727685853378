import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
async function lazyLoadSentryIntegrations() {
    // don't load on server
    if (!process.client) return;

    const { Replay } = await import("@sentry/vue");
    Sentry.addIntegration(new Replay({
        maskAllText: false,
        blockAllMedia: false,
    }));
}
export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        if(process.env.NODE_ENV == 'development') return 
        const vueApp = nuxtApp.vueApp;
        Sentry.init({
            app: vueApp,
            dsn: 'https://016e9a61917cfa30c0c739f5ce710540@sentry-prod.utui.cc/10',
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router)
                })
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            // This sets the sample rate. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: 1.0,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: 1.0,
        });

        // Lazy-load the replay integration to reduce bundle size
        lazyLoadSentryIntegrations();

    }
});